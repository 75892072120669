@use "../abstracts" as *;

/* lps_parts--slide
********************************************** */
.lps_parts--slide {
  .slick-track {
    display: flex;
  }
  .slick-slide {
    height: auto !important;
  }
  .slick-list {
    margin-bottom: rem(32);
  }
  .slick-arrow {
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(60);
    height: 100%;
    text-decoration: none;
    @include mq(sp) {
      width: sprem(32);
    }
    &::after {
      content: "";
      display: block;
      @include rect(30);
      transform: rotate(45deg);
      @include mq(sp) {
        @include sprect(16);
      }
    }
  }
  .slick-prev {
    left: rem(-60);
    @include mq(sp) {
      left: 0;
    }
    &::after {
      margin-right: -20%;
      border-bottom: 2px solid;
      border-left: 2px solid;
    }
  }
  .slick-next {
    right: rem(-60);
    @include mq(sp) {
      right: 0;
    }
    &::after {
      margin-left: -20%;
      border-top: 2px solid;
      border-right: 2px solid;
    }
  }
  .img + .text {
    padding-top: rem(32);
  }
  .ttl-03 {
    @include mq(sp, min, ps) {
      font-size: rem(32);
    }
  }

  /* --- textのみ --- */
  .text:only-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: rem(64) rem(80);
    background-color: var(--color-bg-01);
    height: 100%;
    @include mq(sp) {
      padding: sprem(24) sprem(40);
    }
  }

  /* --- 1枚だけ --- */
  &:has(.slick-slide:only-child) .slick-dots {
    display: none;
  }
}

/* slide-center
  ********************************************** */
.lps_parts--slide.slide-center {
  .slick-slide {
    width: rem(1200);
    margin: 0 rem(30);
    @include mq(1120) {
      width: rem(1000);
    }
    @include mq(sp) {
      width: sprem(320);
      margin: 0 sprem(16);
    }
  }
  .slick-prev {
    left: calc(50% - rem(660));
    @include mq(1120) {
      left: calc(50% - rem(560));
    }
    @include mq(sp) {
      left: calc(50% - sprem(192));
    }
  }
  .slick-next {
    right: calc(50% - rem(660));
    @include mq(1120) {
      right: calc(50% - rem(560));
    }
    @include mq(sp) {
      right: calc(50% - sprem(192));
    }
  }

  /* --- textのみ --- */
  .text:only-child {
    @include mq(sp) {
      padding: sprem(24);
    }
  }
}

/* slide-fit
  ********************************************** */
.lps_parts--slide.slide-fit {
  .slick-track {
    background-color: var(--color-bg-01);
  }
  .slick-slide {
    position: relative;
  }
  .slick-prev {
    left: rem(48);
    @include mq(sp) {
      left: sprem(16);
    }
  }
  .slick-next {
    right: rem(48);
    @include mq(sp) {
      right: sprem(16);
    }
  }
  .img {
    @include mq(sp, min, ps) {
      aspect-ratio: 1920/800;
    }
  }
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - rem(240));
    max-width: rem(1200);
    @include auto-margin;
    @include mq(sp) {
      width: calc(100% - sprem(120));
    }
  }
  .img + .text,
  .slick-arrow {
    color: var(--color-wht);
  }
  .img + .text {
    position: absolute;
    inset: 0;
    padding-top: rem(80);
    padding-bottom: rem(80);
    z-index: 1;
    @include mq(sp) {
      padding-top: sprem(40);
      padding-bottom: sprem(40);
    }
  }
  &:has(.img + .text) .slick-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: rem(80);
    @include mq(sp) {
      bottom: sprem(40);
    }
  }

  /* --- textのみ --- */
  &:has(.text:only-child) .slick-arrow {
    color: var(--color-text-primary);
  }
  .text:only-child {
    padding-left: 0;
    padding-right: 0;
  }
}

/* 背景色調整
  ********************************************** */
.lps_sec:nth-child(even),
.lps_sec[style*="background-color"] {
  .lps_parts--slide .text:only-child,
  .lps_parts--slide.slide-fit .slick-track {
    background-color: var(--color-wht);
    border-radius: rem(24);
  }
}
