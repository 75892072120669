@use "../abstracts" as *;

/* lps_parts--faq
********************************************** */
/* --- 共通 --- */
.lps_parts--faq {
  max-width: rem(960);
  @include auto-margin;
  .ttl-03 {
    font-size: calc-fz(20);
    margin-bottom: 0 !important;
    @include mq(sp) {
      font-size: sprem(17);
    }
  }
  .text {
    @include mq(sp, min, ps) {
      display: flex;
      gap: perc(32, 768, "%");
    }
  }
  .img {
    @include mq(sp, min, ps) {
      width: perc(300, 768, "%");
    }
  }
  .mce-content-body {
    @include mq(sp, min, ps) {
      margin-bottom: 0 !important;
      flex: 1;
    }
  }
}

/* --- 通常 --- */
.lps_parts--faq:not(.faq-toggle) {
  .faq_item {
    border: 2px solid var(--color-primary-default);
    background-color: var(--color-wht);
    &:not(:last-child) {
      margin-bottom: rem(40);
      @include mq(sp) {
        margin-bottom: sprem(16);
      }
    }
  }
  .ttl-03,
  .text {
    padding: rem(28) rem(32);
    @include mq(sp) {
      padding: sprem(24);
    }
  }
  .ttl-03 {
    background-color: var(--color-primary-default);
    color: var(--color-wht);
  }
}

/* --- 開閉式 --- */
.lps_parts--faq.faq-toggle {
  .faq_item {
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
    padding: rem(40) rem(30);
    @include mq(sp) {
      padding: sprem(24) 0;
    }
    &:not(:first-child) {
      border-top: 0;
    }
  }
  .ttl-03 {
    cursor: pointer;
    padding-right: rem(56);
    padding-bottom: 0;
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
    &::before {
      content: "";
      position: absolute;
      inset: rem(-40) rem(-30);
      background-image: none;
      @include mq(sp) {
        inset: sprem(-24);
      }
    }
  }
  .ttl-03 .toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    @include rect(48);
    color: var(--color-wht);
    background-color: var(--color-primary-default);
    border-radius: 50%;
    transition: var(--transit-default);
    &::before,
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      margin: auto;
      width: rem(12);
      height: 2px;
      border-bottom: 2px solid;
      transition: var(--transit-default);
    }
    &::after {
      transform: rotate(90deg);
    }
  }
  .ttl-03:hover .toggle {
    opacity: 0.5;
  }
  .ttl-03.active .toggle {
    transform: rotate(180deg);
    &::after {
      opacity: 0;
    }
  }
  .text {
    padding-top: rem(40);
    @include mq(sp) {
      padding-top: sprem(20);
    }
  }
}
