@use "../abstracts" as *;

/* ##############################################################################

  FORM

############################################################################## */
.nf-form-cont .nf-form-content label,
.nf-form-cont .nf-field-label .nf-label-span {
  font-size: calc-fz(18);
  @include mq(sp) {
    font-size: sprem(15);
    margin-bottom: 6px !important;
  }
}
.nf-form-cont .nf-form-content .nf-label-span {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
}
.nf-form-cont .nf-form-fields-required {
  display: none !important;
}
.nf-form-cont .nf-field-element {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
}
#ninja_forms_required_items,
.nf-form-cont .nf-field-container {
  margin-bottom: rem(24);
  @include mq(sp) {
    margin-bottom: sprem(16);
  }
}
.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element > div,
.nf-form-cont .nf-form-content input:not([type="button"]),
.nf-form-cont .nf-form-content textarea {
  background: var(--color-wht);
  color: var(--color-text-primary);
  border-radius: rem(3);
  vertical-align: bottom;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  font-size: calc-fz(16);
  transition: var(--transit-default, all 0.4s ease-out);
  font-weight: 500;
}
.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element > div:focus,
.nf-form-cont .nf-form-content input:not([type="button"]):focus,
.nf-form-cont .nf-form-content textarea:focus {
  color: var(--color-text-primary);
}
.nf-form-content .list-checkbox-wrap .nf-field-element li label,
.nf-form-content .list-image-wrap .nf-field-element li label,
.nf-form-content .list-radio-wrap .nf-field-element li label {
  cursor: pointer;
}
.nf-form-content input[type="checkbox"]:focus + label:after,
.nf-form-content input[type="radio"]:focus + label:after {
  box-shadow: none;
}
.checkbox-wrap .nf-field-element label.nf-checked-label:before,
.checkbox-wrap .nf-field-label label.nf-checked-label:before,
.listcheckbox-wrap .nf-field-element label.nf-checked-label:before,
.listcheckbox-wrap .nf-field-label label.nf-checked-label:before {
  color: var(--color-primary-default);
}
.nf-form-cont .ninja-forms-req-symbol {
  font-size: 0 !important;
  &::before {
    content: "\5FC5\9808"; /* 必須 */
    background-color: #f55;
    color: var(--color-wht);
    margin-left: 0.5em;
    font-size: calc-fz(12);
    padding: rem(2) rem(5);
    border-radius: rem(3);
    font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  }
}
.nf-form-cont .nf-form-content .submit-wrap {
  text-align: center;
}
.nf-form-cont .nf-form-content .submit-wrap input:not([type="button"]) {
  max-width: 100%;
  @include rect(320, 64);
  font-size: calc-fz(18);
  line-height: 1;
  letter-spacing: 0.1em;
  color: var(--color-wht);
  background-color: var(--color-primary-default);
  border-radius: rem(50);
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  transition: var(--transit-default, all 0.4s ease-out);
  @include mq(sp, min) {
    margin-top: sprem(24);
  }
}
.nf-form-cont .nf-form-errors {
  margin-top: -1em;
  text-align: center;
}

@include mq(sp, min, ps) {
}
@include mq(sp) {
}

/* リキャプチャ・キャプション
**************************************** */
.grecaptcha-badge {
  visibility: hidden;
}
.recaptcha_policy {
  padding: 0;
  margin-top: rem(48);
  color: var(--color-text-primary);
  font-size: calc-fz(10);
  text-align: center;
  letter-spacing: 0;
  @include mq(sp) {
    text-align: left;
  }
}
.recaptcha_policy a {
  color: #69abdb;
  font-size: calc-fz(12);
  text-decoration: underline;
}
