@use "../abstracts" as *;

/* btn
********************************************** */
.btn:not(:last-child) {
  margin-bottom: rem(8);
}
.btn a {
  position: relative;
  z-index: 1;
  display: block;
  padding: rem(12) rem(58) rem(12) rem(40);
  border-radius: 9999px;
  width: fit-content;
  min-width: min(rem(200), 100%);
  color: var(--color-wht);
  text-align: center;
  background-color: var(--color-primary-default);
  @include mq(sp) {
    min-width: 100%;
    padding-block: sprem(16);
  }
  &:hover {
    background-color: var(--color-primary-light);
  }
  &:where(:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]))::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: rem(32);
    @include rect(8);
    border-top: 2px solid;
    border-right: 2px solid;
    rotate: 45deg;
    transition: var(--transit-default);
  }
  svg {
    right: rem(24);
    fill: currentColor;
  }
}
.btn.btn-ctr a {
  @include auto-margin;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--color-primary-default);
  background-color: var(--color-wht);
  &:hover {
    color: var(--color-wht);
    background-color: var(--color-primary-default);
  }
  svg {
    fill: var(--color-primary-default);
  }
  &:hover svg {
    fill: var(--color-wht);
  }
}

/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--color-secondary-default);
  &:hover {
    background-color: var(--color-secondary-dark);
  }
}

/* --- btn-arrow --- */
.btn-arrow a {
  position: relative;
  display: inline-block;
  align-items: center;
  padding: rem(20) rem(80) rem(20) rem(8);
  font-size: rem(16);
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.01em;
  &:hover {
    color: var(--color-primary-light);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transition: var(--transit-default);
  }
  &::before {
    right: 0;
    width: vw(56);
    height: vw(56);
    border-radius: 100px;
    background-color: var(--color-primary-default);
    @include mq(sp) {
      width: sprem(28);
      height: sprem(28);
    }
  }
  &::after {
    right: vw(18);
    width: vw(19);
    height: vw(18);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/btn-arrow.svg);
    mask-repeat: no-repeat;
    mask-size: 100%;
    background-color: var(--color-wht);
    @include mq(sp) {
      width: sprem(17);
      height: sprem(16);
      margin-top: -1px;
    }
  }
  &:hover {
    &::before {
      background-color: var(--color-wht);
    }
    &::after {
      background-color: var(--color-primary-default);
    }
  }
  @include mq(sp) {
    padding: sprem(10) sprem(40) sprem(10) sprem(4);
    font-size: sprem(16);
  }
}

/* --- 横並び --- */
.flex > .btn {
  margin-bottom: 0;
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
  &:only-child {
    @include mq(sp, min, ps) {
      width: fit-content;
      min-width: min(rem(280), 100%);
    }
  }
  a {
    min-width: 100%;
    height: 100%;
  }
}
