@use "../abstracts" as *;

/* cta
********************************************** */
.cta {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: vw(126);
    left: vw(131);
    width: vw(350);
    height: vw(488);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/cta-deco02.png);
    background-repeat: no-repeat;
    background-size: 100%;
    @include mq(sp) {
      content: none;
    }
  }
  .ttl-01-sub {
    margin-bottom: vw(42) !important;
    @include mq(sp) {
      margin-bottom: sprem(21) !important;
    }
  }
}
.cta .inner {
  max-width: vw(1050);
  @include mq(sp) {
    max-width: rem(1050);
  }
}
.cta--wrap .ttl-01::before {
  width: vw(60);
  height: vw(48);
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/icon-mail_cta.svg);
  @include mq(sp) {
    width: sprem(30);
    height: sprem(24);
  }
}
.cta--txt {
  font-size: rem(18);
  line-height: 2.2;
  padding-block: vw(18) vw(30);
  @include mq(sp) {
    text-align: left;
    padding-block: sprem(12) sprem(15);
  }
}
.cta--area {
  color: var(--color-text-light);
  background-color: var(--color-primary-default);
  border-radius: rem(6);
  padding: vw(4) vw(12);
  @include mq(sp) {
    width: fit-content !important;
    padding: sprem(2) sprem(11);
  }
}
.cta--area--txt {
  font-size: rem(18);
  line-height: 2.2;
  color: var(--color-primary-default);
  padding-left: vw(24);
  @include mq(sp) {
    padding-left: 0;
    padding-top: sprem(6);
  }
}
.cta--box {
  flex-direction: column;
  width: vw(500);
  border-radius: 18px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 24px 0px rgba(84, 0, 0, 0.1);
  padding-block: vw(30) vw(36);
  @include mq(sp) {
    padding-block: sprem(15) sprem(18);
  }
}
.cta--box .cta_contact {
  margin-inline: auto !important;
  margin-top: vw(20);
  a {
    font-size: rem(18);
    padding: vw(22) vw(66);
    @include mq(sp) {
      font-size: sprem(16);
      padding: sprem(11) sprem(33);
    }
  }
  @include mq(sp) {
    margin-top: sprem(10);
  }
}
.cta--box-tel {
  flex-direction: column;
  .tel img {
    width: vw(56) !important;
    @include mq(sp) {
      width: sprem(34) !important;
    }
  }
  .num {
    font-size: rem(48);
    line-height: 1.7;
    letter-spacing: 0.08em;
    color: var(--color-primary-default);
    padding-left: vw(10);
    @include mq(sp) {
      font-size: sprem(32);
      padding-left: sprem(8);
    }
  }
  .hours {
    color: var(--color-text-secondary);
    margin-top: vw(-8);
    @include mq(sp) {
      margin-top: sprem(-8);
      font-size: sprem(12);
    }
  }
}
.line--box {
  position: relative;
  width: vw(500);
  border-radius: 18px;
  background: var(--color-primary-default);
  box-shadow: 0px 0px 24px 0px rgba(84, 0, 0, 0.1);
  padding-block: vw(46) vw(36);
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  &::before {
    top: vw(-40);
    right: vw(-147);
    width: vw(263);
    height: vw(112);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/line-bnr.svg);
    @include mq(sp) {
      top: sprem(-30);
      right: sprem(-22);
      width: sprem(131);
      height: sprem(56);
    }
  }
  &::after {
    bottom: vw(-71);
    right: vw(-300);
    width: vw(175);
    height: vw(239);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/cta-deco01.png);
    @include mq(sp) {
      bottom: sprem(-45);
      right: sprem(-20);
      width: sprem(87);
      height: sprem(119);
    }
  }
  @include mq(sp) {
    width: sprem(250);
    margin-top: sprem(32);
    padding-block: sprem(23) sprem(18);
  }
}
.line--box .cta_contact {
  margin-inline: auto;
  margin-top: vw(28);
  a {
    font-size: rem(18);
    line-height: 1;
    color: #12a132;
    background-color: var(--color-text-light);
    padding: vw(25) vw(81);
    &:hover {
      opacity: 0.8;
    }
    @include mq(sp) {
      min-width: auto;
      padding: sprem(12) sprem(40);
    }
  }
  @include mq(sp) {
    margin-top: sprem(14);
  }
}
.line--txt {
  font-size: rem(24);
  line-height: 1.7;
  letter-spacing: 0.08em;
  color: var(--color-text-light);
}
.cta--deco {
  position: absolute;
  bottom: vw(-170);
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  z-index: 1;
  img {
    min-width: vw(1661);
  }
  @include mq(sp) {
    bottom: sprem(-52);
    width: 100%;
  }
}

/* ##############################################################################

    FOOTER

############################################################################## */

.footer {
  background-color: var(--color-primary-default);
  .cta_contact {
    margin-right: 0 !important;
    @include mq(sp) {
      padding-block: sprem(18) sprem(24);
      a {
        justify-content: center;
        font-size: sprem(16);
      }
    }
  }
  .inner-lg {
    padding-block: vw(200) vw(120);
    @include mq(sp) {
      padding-block: sprem(100) sprem(60);
    }
  }
}
.footer--wrap {
  margin-bottom: vw(30);
  @include mq(sp) {
    margin-bottom: sprem(16);
  }
}
.footer--deco {
  width: 100% !important;
}
@include mq(sp) {
  .footer--deco {
    height: sprem(72);
    object-fit: cover;
  }
}

/* footer_top
********************************************** */
.footer-rgt {
  flex: 1;
}
.footer--logo img {
  width: vw(285) !important;
  @include mq(sp) {
    width: sprem(180) !important;
  }
}
.footer--address {
  padding-top: vw(30);
  color: var(--color-text-light);
  a {
    color: var(--color-text-light);
  }
}
/* ---fnav --- */
.fnav--menu {
  flex-direction: column;
  align-content: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
  height: rem(108);
  @include mq(sp) {
    padding-top: sprem(24);
    height: fit-content;
  }
}
.fnav--menu li {
  margin-left: vw(64);
}
.fnav--menu a {
  display: block;
  font-size: rem(16);
  line-height: 1;
  padding: 0.5em 1em;
  color: var(--color-text-light);
  &:hover {
    opacity: 0.7;
  }
  @include mq(sp) {
    position: relative;
    padding: 1em 0.5em;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0.5em;
      margin: auto;
      @include sprect(6);
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
    }
  }
}
.footer-sub {
  padding-top: vw(32);
  @include mq(sp) {
    padding-top: sprem(14);
  }
}
.fnav--menu.sub-menu {
  flex-direction: row;
  justify-content: left;
  height: auto;
  margin-left: vw(-12);
  li {
    margin-left: 0;
    a {
      font-size: rem(14);
      line-height: 1.7;
      color: var(--color-text-placeholder);
    }
    @include mq(sp) {
      width: 45% !important;
    }
  }
  @include mq(sp) {
    justify-content: space-between;
    margin-left: 0;
    padding-top: 0;
  }
}

/* ---sns_area --- */
.sns_area {
  margin-left: vw(-12);
  @include mq(sp) {
    margin-left: 0;
    padding-left: 0.5em;
  }
}
.sns_area a {
  display: grid;
  place-items: center;
  padding-inline: vw(12);
  &:hover {
    opacity: 0.7;
  }
  @include mq(sp) {
    padding-inline: sprem(6);
  }
}
.sns_area svg {
  @include rect(27);
  fill: var(--color-wht);
  &.icon-x {
    @include rect(22);
  }
}
.sns_area .youtube {
  display: flex;
  align-items: center;
  font-size: rem(20);
  line-height: 1.6;
  letter-spacing: normal;
  color: var(--color-wht);
  .icon-svg {
    margin-left: vw(6);
    @include rect(16);
  }
}

/* footer_btm
*************************************************** */
.footer_btm {
  padding: rem(8) 0;
}
.footer_btm:has(.privacy) {
  @include mq(sp, min, ps) {
    & > .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.privacy {
  @include mq(sp) {
    text-align: center;
    margin-bottom: 0.5em;
  }
}
.privacy a:hover {
  opacity: 0.7;
}
.copyright {
  gap: vw(24);
  justify-content: center;
  @include mq(sp) {
    gap: 0.5em;
    padding-top: sprem(24);
  }
  small {
    line-height: 1.6;
    letter-spacing: normal;
    color: var(--color-text-secondary);
    @include mq(sp) {
      text-align: center;
    }
  }
}
.pbl a,
.pbl img {
  display: block;
  width: vw(150) !important;
  @include mq(sp) {
    width: sprem(120) !important;
    margin-inline: auto;
    padding-top: sprem(4);
  }
}
.pbl a {
  opacity: 0.25;
  &:hover {
    opacity: 0.6;
  }
}
