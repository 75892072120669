@use "../abstracts" as *;

/* --- section_pdg --- */
.section_pdg {
  padding-block: rem(40) rem(260);
  &:first-child {
    padding-top: rem(160);
    @include mq(sp) {
      padding-top: sprem(72);
    }
  }
  @include mq(sp) {
    padding-block: sprem(32) sprem(100);
  }
}
.section_pdg-sm {
  padding-block: rem(80);
  @include mq(sp) {
    padding-block: sprem(24);
  }
}

.page-404 .section_pdg {
  padding-block: rem(120) rem(260);
  @include mq(sp) {
    padding-block: sprem(32) sprem(100);
  }
}
