@use "../abstracts" as *;

/* bg
********************************************** */
.bg-primary {
  background-color: var(--color-primary-default);
}
.bg-01 {
  background-color: var(--color-bg-01);
}
.bg-02 {
  background-color: var(--color-bg-02);
}
.bg-wh {
  background-color: var(--color-wht);
}
.bg-ptn01 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn01.jpg) !important;
  &::before {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/section-line01.png) !important;
  }
}
.bg-ptn02 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn02.jpg) !important;
  &::before {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/section-line02.png) !important;
  }
  @include mq(sp) {
    background-size: contain;
  }
}
.bg-ptn03 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn03.jpg) !important;
  &::before {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/section-line03.png) !important;
  }
}
.bg-ptn04 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn04.jpg) !important;
  &::before {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/section-line04.png) !important;
  }
}
.bg-ptn05 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn05.svg) !important;
}
.bg-ptn06 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn06.jpg) !important;
}
