@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 16;
$fz-sp: 14;
$wid-pc: 1920;
$wid-sp: 414;
$wid-var: 1700; // フォントサイズは画面幅がこの値～$wid-pcで可変する
$header-hgt: 113;
$header-hgt-sp: 74;
$header-hgt-fx: 108;

// 色
$clr-main: #0d2a52;
$bg-primary: #0d2a52;

// CSS変数
:root {
  // typography
  --ltr-space-default: 0.03em;
  --line-height-default: 2;
  --line-height-hdr: 1.4;
  --line-height-none: 1;

  // font-families
  --font-primary: "Shuei MaruGo B", sans-serif;
  --font-secondary: "Shuei MaruGo B", sans-serif;
  --font-jp: "A1 Mincho", sans-serif;
  --font-en: "Homemade Apple", sans-serif;
  --font-en2: "Libre Baskerville", sans-serif;

  // colors
  --color-primary-default: #0d2a52;
  --color-primary-dark: #0f6593;
  --color-primary-light: #3e5e8a;

  /* ※CTA周りは、デフォルトは secondary を当てています。自由に変えてください */
  --color-secondary-default: #ed9886;
  --color-secondary-dark: #d56952;
  --color-secondary-light: #ffaca0;

  --color-bg-01: #fdfcf8;
  --color-bg-02: #f7f5ef;

  --color-sub-01: #51aaa6;
  --color-sub-02: #a8bf71;
  --color-sub-03: #d7a761;
  --color-sub-04: #cf727c;
  --color-sub-05: #a2689b;
  --color-sub-06: #6790b7;

  --color-mono-01: #f2f2f2;
  --color-mono-02: #dfdfe0;
  --color-mono-03: #babdc4;
  --color-mono-04: #9599a3;
  --color-mono-05: #646b7a;
  --color-mono-06: #333841;

  --color-text-primary: #404044;
  --color-text-secondary: #7a7a81;
  --color-text-placeholder: #ccc;
  --color-text-light: #f7f7f7;
  --color-text-line: #d3d3d3;

  --color-wht: #fff;
  --color-blk: #000;

  // animation
  --transit-default: all 0.4s ease-out;

  // formy settings
}

// SASS Maps

$z-index: (
  "header": 9999,
  "top": 9990,
  "aside": 75,
  "middle": 50,
  "main": 1,
  "base": 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160,
  // 2560px
  lg: 120,
  // 1920px
  pc: 85.375,
  // 1366px
  med: 67.5,
  // 1080px
  sp: 47.9375,
  // 767px
  sm: 40,
  // 640px
  xs: 34.375,
  // 500px
  se: 21.25,
  // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$colors: (
  "blue": (
    100: hsl(202, 73%, 82%),
    200: hsl(202, 73%, 72%),
    300: hsl(202, 73%, 62%),
    400: hsl(202, 73%, 52%),
    500: hsl(202, 73%, 42%),
    600: hsl(202, 73%, 32%),
  ),
  "red": (
    100: hsl(0, 100%, 80%),
    200: hsl(0, 100%, 70%),
    300: hsl(0, 100%, 60%),
    400: hsl(0, 100%, 50%),
    500: hsl(0, 100%, 40%),
    600: hsl(0, 100%, 30%),
  ),
  "green": (
    100: hsl(118, 100%, 80%),
    200: hsl(118, 100%, 70%),
    300: hsl(118, 100%, 60%),
    400: hsl(118, 100%, 50%),
    500: hsl(118, 100%, 40%),
    600: hsl(118, 100%, 30%),
  ),
);

$aspect-ratios: (
  widescreen: "3/2",
  square: "1/1",
  portrait: "2/3",
);
