@use "../abstracts" as *;

/* ##############################################################################

    SINGLE

############################################################################## */

/* --- アイキャッチ --- */
.eyecatch {
  margin-bottom: rem(32);
}

/* --- 目次 --- */
.ez-toc-debug-messages {
  display: none;
}
div#ez-toc-container {
  padding: rem(24);
  background-color: #fafafa;
}
.ez-toc-title-container {
  margin-bottom: rem(10);
}
div#ez-toc-container p.ez-toc-title {
  color: var(--color-text-primary);
  font-weight: bold;
  font-size: calc-fz(20);
}
#ez-toc-container.counter-hierarchy ul {
  font-size: calc-fz(17);
}
#ez-toc-container.counter-hierarchy ul li {
  margin-top: rem(8);
}
#ez-toc-container.counter-hierarchy ul ul {
  margin-left: rem(16);
}
#ez-toc-container.counter-hierarchy ul ul a {
  position: relative;
  display: inline-block;
  padding-left: rem(16);
  &::before {
    content: "";
    position: absolute;
    top: rem(10);
    left: 0;
    width: rem(8);
    height: 0;
    border-bottom: 1px solid;
  }
}

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}
#pass input[name="post_password"] {
  padding: rem(5);
  border: solid 1px #aaa;
  outline: none;
}
#pass input[type="submit"] {
  padding: rem(4) rem(6);
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: rem(4);
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transit-default);
}
#pass input[type="submit"]:hover {
  opacity: 0.7;
}

/* share
**************************************** */
.share--list a {
  @include center-flex;
  height: rem(40);
  padding: 0.5em;
  line-height: 1;
  font-size: calc-fz(12);
  font-weight: bold;
  border: 1px solid #ddd;
}
.share--list li {
  &.x a {
    color: #000;
  }
  &.facebook a {
    color: #2477f2;
  }
  &.line a {
    color: #27c754;
  }
  &.pocket a {
    color: #ef4056;
  }
  &.linkedin a {
    color: #2867b2;
  }
  &.hatena a {
    color: #29a4de;
  }
}
.share--list img {
  margin-right: rem(10);
}
.share--list a:hover {
  opacity: 0.5;
}

/* works
**************************************** */
.single-works .works_info .table2 {
  width: 100%;
  th {
    width: 240px;
    text-align: center;
    @include mq(sp) {
      width: 140px;
    }
  }
}
.single-works .content-left,
.single-works .content-right {
  position: relative;
  width: calc((100% - 80px) / 2);
  span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    padding: 8px 16px;
    font-family: "futura-pt", sans-serif;
    font-weight: bold;
    letter-spacing: 0.2em;
    line-height: 1;
    color: var(--color-wht);
    background-color: var(--color-primary-default);
    border-radius: 8px 0 0 0;
  }
  img {
    width: 100% !important;
    margin: 0;
    border-radius: 8px;
  }
}
.single-works .content-left {
  margin-bottom: rem(40);
  &::before {
    content: "";
    position: absolute;
    top: calc(50% + 12px);
    right: -50px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 0 25px 20px;
    border-color: transparent transparent transparent var(--color-primary-default);
    @include mq(sp) {
      top: inherit;
      left: 50%;
      bottom: -32px;
      right: inherit;
      transform: translateX(-50%);
      border-width: 20px 25px 0 25px;
      border-color: var(--color-primary-default) transparent transparent transparent;
    }
  }
  @include mq(sp) {
    margin-bottom: sprem(56);
  }
}
.single-works .main_column {
  margin-right: vw(80);
}
@include mq(sp) {
  .works_befoaf .content_wrapper:not(:last-child) {
    margin-bottom: sprem(48);
  }
  .single-works .side_column {
    padding-top: sprem(56);
  }
  .single-works .post--date {
    margin-bottom: sprem(4);
  }
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: calc-clamp(4, $wid-var);
  white-space: nowrap;
}
.wp-pagenavi > * {
  @include center-flex;
}
.wp-pagenavi .pages {
  width: 100%;
  margin-bottom: 1em;
}
.wp-pagenavi a {
  position: relative;
  padding: 0.5em 1.4em;
  background-color: var(--color-primary-default);
  color: var(--color-wht);
  border-radius: 4px;
  &:hover {
    background-color: var(--color-primary-dark);
  }
}
.archive .wp-pagenavi > *:not(.pages) {
  width: calc-clamp(40, $wid-var);
  aspect-ratio: 1/1;
  @include mq(sp) {
    width: sprem(36);
  }
}
.wp-pagenavi-single a[rel="prev"],
.wp-pagenavi-single a[rel="next"] {
  aspect-ratio: 1/1;
}
.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  border-top: 2px solid;
  border-right: 2px solid;
}
.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  width: 0.5em;
  aspect-ratio: 1/1;
}
.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .first::after {
  transform: rotate(-135deg);
}
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .last::before,
.wp-pagenavi .last::after {
  transform: rotate(45deg);
}
.wp-pagenavi-single a[rel="prev"]::before {
  transform: rotate(45deg);
}
.wp-pagenavi-single a[rel="next"]::before {
  transform: rotate(-135deg);
}
.wp-pagenavi .first::before,
.wp-pagenavi .last::before {
  left: rem(-6);
}
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  right: rem(-6);
}

/* blog
********************************************** */
  .blog_ttl_area--txt {
    flex: 1;
    @include mq(sp) {
      margin-bottom: sprem(16);
    }
  }
  .blog_ttl_area .eyecatch {
    width: rem(330);
    margin-left: rem(40);
    @include mq(sp) {
      width: 100%;
      margin-left: 0;
    }
  }

/* blog-card
********************************************** */
/* --- blog_card --- */
.blog_card {
	position: relative;
  margin-top: rem(56);
  margin-bottom: rem(40);
  padding: rem(32) rem(24) rem(24);
  border: 1px solid var(--color-text-primary);
}
.blog_card--caption {
  position: absolute;
  top: -1em;
  left: 32px;
  z-index: 1;
  display: inline-block;
  padding: 0 12px;
  font-size: clamp(14px, calc(16 / 1920 * 100vw), 18px);
  line-height: 2;
  background: #fff;
  border-radius: 100vmax;
}
.blog_card--content {
  display: grid;
  grid-template-columns: rem(200) 1fr;
  align-items: center;
  gap: rem(24);
  @include mq(sp) {
    grid-template-columns: sprem(120) 1fr;
  }
}
.blog_card--img {
  aspect-ratio: 3/2;
  img {
    margin: 0;
    height: 100%;
  }
}
.blog_card--txtarea {
  flex: 1;
}
.blog_card--ttl {
  font-size: rem(18);
  margin-bottom: rem(8);
}
.blog_card--txt {
  font-size: rem(14);
}