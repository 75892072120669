@use "../abstracts" as *;

/* cta_box
**************************************** */
.cta_box {
  display: flex;
  justify-content: center;
  gap: rem(24);
  @include mq(sp) {
    flex-direction: column;
    align-items: center;
    gap: sprem(10);
  }
}
.cta_box a {
  height: rem(80);
  @include mq(sp, min) {
    width: rem(320);
  }
}
@include mq(sp) {
  .cta_box li {
    width: 100%;
  }
}

/* cta_tel
**************************************** */
.cta_tel a {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: rem(12);
  line-height: var(--line-height-hdr);
  border-radius: rem(4);
}
.cta_tel a > span {
  display: block;
}
.cta_tel .tel {
  display: flex;
  align-items: center;
  font-size: rem(14);
  letter-spacing: 0.1em;
  color: var(--color-primary-light);
}
.cta_tel .num {
  font-family: var(--font-jp);
  font-size: rem(32);
  letter-spacing: 0.08em;
  padding-left: vw(10);
  @include mq(sp) {
    padding-left: sprem(8);
  }
}
.cta_tel .hours {
  font-size: rem(12);
  line-height: 1.3;
  letter-spacing: 0.1em;
  color: var(--color-text-secondary);
}

/* cta_contact
**************************************** */
.cta_contact {
  @include mq(med, min, ps) {
    margin-left: rem(32);
  }
  img {
    margin-right: vw(16);
    @include mq(sp) {
      margin-right: sprem(8);
    }
  }
}
.cta_contact a {
  display: flex;
  align-items: center;
  height: fit-content !important;
  letter-spacing: 0.03em;
  padding: vw(16) vw(32);
  border-radius: 100px;
  color: var(--color-primary-default);
  background-color: var(--color-secondary-default);
  &:hover {
    background-color: var(--color-secondary-light);
  }
  @include mq(med) {
    padding: sprem(10) sprem(16);
    margin-left: auto;
    margin-right: auto;
  }
  @include mq(sp) {
    padding-block: sprem(16);
  }
}
.cta_contact .micro_copy {
  position: absolute;
  top: calc(-1em - rem(10));
  inset-inline: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: rem(13);
  color: var(--color-secondary-default);
  text-align: center;
  line-height: 1;
  &::before,
  &::after {
    content: "";
    width: rem(2);
    height: rem(16);
    background-color: var(--color-secondary-default);
  }
  &::before {
    transform: rotate(-45deg);
  }
  &::after {
    transform: rotate(45deg);
  }
}
