@use "../abstracts" as *;

/* section-contact
********************************************** */
.section-contact {
  .cta_tel {
    width: fit-content;
    max-width: rem(400);
    @include auto-margin;
  }
  .cta_tel a {
    padding: rem(24) rem(40);
    background-color: var(--color-secondary-default);
    border-radius: 100px;
    @include mq(sp) {
      display: block;
      padding: sprem(16) sprem(24);
    }
    span {
      color: var(--color-wht);
      padding-left: rem(6);
    }
  }
}
