@use "../abstracts" as *;

/* anchor_link
********************************************** */
.anchor_link--list_wrap {
  position: relative;
  z-index: 100;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn02.jpg);
}
.anchor_link--list {
  @include mq(sp) {
    flex-wrap: inherit;
    width: 100%;
    white-space: nowrap;
    overflow: auto;
  }
}
.anchor_link--list li {
  min-height: rem(96);
  @include mq(sp) {
    width: auto;
    min-height: sprem(64);
    &:first-child {
      margin-left: auto;
      padding-left: sprem(24);
    }
    &:last-child {
      margin-right: auto;
      padding-right: sprem(24);
    }
    &:first-child a {
      padding-left: 0;
    }
  }
}
.anchor_link--list a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5em 2.5em;
  line-height: var(--line-height-hdr);
  @include mq(sp) {
    padding: 1em 1.5em;
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: rem(24);
    transform: translateY(-50%);
    @include rect(12, 6);
    background-color: var(--color-text-primary);
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    transition: var(--transit-default);
    @include mq(sp) {
      right: 0;
    }
  }
  &:hover {
    opacity: 0.7;
  }
}
