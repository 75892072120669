@use "../abstracts" as *;

/* ##############################################################################

    oneday

############################################################################## */

/* oneday_about
********************************************** */
.txt-vertical {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-feature-settings: initial;
  @include mq(sp) {
    writing-mode: lr;
    font-feature-settings: inherit;
  }
}
.oneday_about {
  padding-top: vw(142);
  overflow: hidden;
  .ttl-01 {
    margin-bottom: rem(80) !important;
    @include mq(sp) {
      margin-bottom: sprem(30) !important;
    }
    img {
      display: block;
      margin-inline: auto;
      padding-block: rem(9) rem(13);
      @include mq(sp) {
        width: sprem(251) !important;
        padding-block: sprem(4) sprem(6);
      }
    }
  }
  .inner {
    max-width: vw(1524);
    @include mq(sp) {
      max-width: rem(1524);
    }
  }
  @include mq(sp) {
    padding-top: sprem(71);
  }
}
.oneday_about--txt {
  font-size: rem(18);
  color: var(--color-wht);
  margin-bottom: rem(80);
  @include mq(sp) {
    font-size: sprem(13);
    margin-bottom: sprem(50);
  }
}
.oneday_about--deco {
  animation: oneday_move 18s linear infinite;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  margin-bottom: rem(161);
  img {
    @include mq(sp) {
      object-fit: cover;
    }
  }
  @include mq(sp) {
    margin-bottom: sprem(43);
    animation: oneday_move-sp 8s linear infinite;
  }
}
.profile--area {
  flex-direction: row-reverse;
  color: var(--color-primary-default);
  margin-right: vw(148);
  img {
    padding-top: rem(7);
    @include mq(sp) {
      width: sprem(95) !important;
      padding-block: 0 sprem(18);
    }
  }
  @include mq(sp) {
    display: block;
    margin-right: 0;
    text-align: center;
  }
}
.profile--txt {
  font-size: rem(18);
  padding-top: vw(80);
  p {
    letter-spacing: 0.11em;
    &:not(:last-child) {
      padding-left: rem(50);
      @include mq(sp) {
        padding-left: 0;
      }
    }
    @include mq(sp) {
      text-align: center;
      font-size: sprem(13);
      letter-spacing: 0.03em;
      padding-bottom: 1rem;
    }
  }
  span:not(.sp-space) {
    text-combine-upright: all;
    -webkit-text-combine: horizontal;
    -ms-text-combine-horizontal: all;
    padding-bottom: vw(4);
  }
  @include mq(sp) {
    padding-block: sprem(40) sprem(30);
  }
}
@include mq(sp) {
  .sp-space {
    display: block;
    margin-top: -0.5rem;
  }
}
.profile--ttl {
  font-size: rem(62);
  line-height: 1.1;
  padding-left: rem(83);
  span {
    display: block;
    &:first-child {
      padding-right: rem(27);
      padding-left: rem(36);
      @include mq(sp) {
        padding-inline: 0;
      }
    }
    @include mq(sp) {
      line-height: 1.6;
    }
  }
  @include mq(sp) {
    font-size: sprem(31);
    padding-left: 0;
  }
}
.profile--deco {
  position: relative;
  img {
    width: 100% !important;
    margin-top: vw(-167);
    @include mq(sp) {
      margin-top: sprem(-118);
    }
  }
  @include mq(sp) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}
.profile--list {
  position: absolute;
  .theme {
    display: flex;
    align-items: center;
    font-size: rem(20);
    line-height: 2.2;
    color: var(--color-wht);
    padding-left: vw(25);
    &::before {
      content: "";
      display: block;
      width: vw(8);
      height: vw(8);
      border-radius: 100px;
      background-color: var(--color-wht);
      margin-right: vw(8);
      @include mq(sp) {
        width: sprem(4);
        height: sprem(4);
        margin-right: sprem(4);
      }
    }
    @include mq(sp) {
      font-size: sprem(13);
      padding-left: sprem(13);
    }
  }
  .txt {
    position: relative;
    line-height: 1.6;
    background-color: var(--color-bg-01);
    min-width: vw(267);
    text-align: center;
    border-radius: 100px;
    padding: vw(22) vw(32);
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      bottom: vw(-22);
      width: vw(27);
      height: vw(22);
      background-image: url(/wp/wp-content/themes/standard_sass/images/oneday/triangle.svg);
      background-size: 100%;
      background-repeat: no-repeat;
      @include mq(sp) {
        bottom: sprem(-10);
        width: sprem(14);
        height: sprem(11);
      }
    }
    @include mq(sp) {
      min-width: auto;
      width: fit-content;
      font-size: sprem(13);
      padding: sprem(11) sprem(16);
    }
  }
  @include mq(sp) {
    position: relative;
    display: block;
    width: fit-content;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin-bottom: sprem(31);
  }
}
.profile01 {
  top: vw(80);
  left: vw(452);
}
.profile02 {
  top: vw(256);
  left: vw(225);
  @include mq(sp) {
    margin-left: auto;
  }
}
.profile03 {
  top: vw(432);
  left: vw(324);
}
.profile04 {
  top: vw(85);
  right: vw(451);
  @include mq(sp) {
    margin-left: auto;
  }
}
.profile05 {
  top: vw(261);
  right: vw(171);
}
.profile06 {
  top: vw(437);
  right: vw(414);
  @include mq(sp) {
    margin-left: auto;
  }
}
@include mq(sp) {
  .inner_sp {
    width: 86%;
    max-width: rem(1524);
    margin-inline: auto;
  }
}

/* oneday_schedule
********************************************** */
.oneday_schedule {
  padding-block: vw(40) 0;
  @include mq(sp) {
    padding-block: sprem(20) 0;
  }
}
.oneday_schedule--inner {
  overflow: hidden;
}
.oneday_schedule .ttl-01-sub {
  position: relative;
  padding-bottom: vw(145);
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
    @include mq(sp) {
      content: none;
    }
  }
  &::before {
    bottom: 0;
    right: vw(181);
    width: vw(294);
    height: vw(325);
    background-image: url(/wp/wp-content/themes/standard_sass/images/oneday/schedule-deco01.png);
  }
  &::after {
    bottom: vw(-51);
    left: vw(250);
    width: vw(173);
    height: vw(250);
    background-image: url(/wp/wp-content/themes/standard_sass/images/reason01-deco02.png);
    z-index: 2;
  }
}
.schedule--inner {
  width: vw(1276);
  @include mq(sp) {
    max-width: sprem(1200);
    width: 86%;
  }
}
.schedule--area {
  position: relative;
  border-radius: rem(18);
  padding-block: vw(162) vw(251);
  z-index: 1;
  @include mq(1440) {
    padding-bottom: vw(300);
  }
  @include mq(sp) {
    border-radius: sprem(9);
    padding-bottom: sprem(180);
  }
}
.schedule--num {
  font-family: var(--font-jp);
  font-size: rem(32);
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.12em;
  color: var(--color-primary-default);
  &.first {
    font-size: rem(62);
    text-align: center;
    padding-bottom: vw(45);
  }
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.schedule--list.rgt {
  right: 0;
  &::before {
    left: vw(-24);
    transform: rotate(90deg);
  }
}
.schedule--list.lft {
  left: vw(68);
  &::before {
    right: vw(-24);
    transform: rotate(-90deg);
  }
  @include mq(sp) {
    left: auto;
  }
}
.schedule--list.lft0 {
  left: 0;
}
.schedule--list {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: fit-content;
  height: fit-content;
  max-width: vw(496);
  min-width: vw(496);
  padding: vw(32) vw(40);
  border-radius: rem(18);
  background-color: var(--color-bg-01);
  .ttl {
    display: flex;
    align-items: center;
    font-size: rem(20);
    line-height: 2.2;
    color: var(--color-primary-default);
    &::before {
      content: "";
      display: block;
      width: vw(8);
      height: vw(8);
      border-radius: 100px;
      background-color: var(--color-secondary-default);
      margin-right: vw(8);
      @include mq(sp) {
        width: sprem(4);
        height: sprem(4);
        margin-right: sprem(4);
      }
    }
    @include mq(sp) {
      font-size: sprem(13);
    }
  }
  .txt {
    padding-top: vw(6);
    @include mq(sp) {
      font-size: sprem(13);
      padding-top: 0;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: vw(27);
    height: vw(22);
    background-image: url(/wp/wp-content/themes/standard_sass/images/oneday/triangle.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    @include mq(sp) {
      content: none;
    }
  }
  .schedule--num {
    position: absolute;
    top: vw(-45);
    left: 0;
    @include mq(sp) {
      top: sprem(-30);
      left: sprem(20);
    }
  }
  @include mq(sp) {
    position: relative;
    display: block;
    min-width: 100%;
    max-width: none;
    border-radius: sprem(9);
    padding: sprem(16) sprem(20);
    margin-top: sprem(-13);
    z-index: 1;
  }
}
.schedule--icon {
  position: relative;
  width: fit-content;
  margin-inline: auto;
  z-index: 1;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: var(--color-primary-default);
    width: vw(3);
    height: vw(320);
    z-index: -1;
    @include mq(sp) {
      width: sprem(1.5);
      height: sprem(375);
    }
  }
  &::before {
    top: 0;
    bottom: 50%;
  }
  &::after {
    top: 50%;
    bottom: 0;
  }
  @include mq(sp) {
    width: sprem(80);
    z-index: inherit;
    img {
      position: relative;
      z-index: 2;
    }
  }
}
@include mq(sp) {
  .circle_sp {
    display: none;
  }
}
.schedule--box:last-child .schedule--icon::after,
.schedule--box:last-child .schedule--icon::before,
.schedule--box:first-of-type .schedule--icon::before {
  content: none !important;
}
.schedule--box {
  position: relative;
  &.box01 {
    margin-bottom: vw(241);
  }
  &.box02 {
    margin-bottom: vw(267);
  }
  &.box03 {
    margin-bottom: vw(175);
  }
  &.box04 {
    margin-bottom: vw(162);
  }
  &.box05 {
    margin-bottom: vw(187);
  }
  &.box06 {
    margin-bottom: vw(224);
  }
  &.box07 {
    margin-bottom: vw(178);
  }
  &.box08 {
    margin-bottom: vw(123);
  }
  &.box09 {
    margin-bottom: vw(161);
  }
  &.box10 {
    margin-bottom: vw(214);
  }
  &.box11 {
    margin-bottom: vw(130);
  }
  &.box12 {
    margin-bottom: vw(137);
  }
  &.box13 {
    margin-bottom: vw(177);
  }
  @include mq(sp) {
    &.box02,
    &.box04,
    &.box08,
    &.box11,
    &.box13 {
      margin-bottom: sprem(40) !important;
    }
    &.box01,
    &.box03,
    &.box05,
    &.box06,
    &.box07,
    &.box09,
    &.box10,
    &.box12 {
      margin-bottom: sprem(72) !important;
    }
  }
}
.schedule--deco {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
  &.deco01 {
    right: vw(361);
    @include mq(sp) {
      right: sprem(-196);
      width: sprem(90);
    }
  }
  &.deco02 {
    right: vw(400);
    @include mq(sp) {
      right: sprem(-210);
      width: sprem(108);
    }
  }
  &.deco03 {
    right: vw(422);
    @include mq(sp) {
      right: sprem(-218);
      width: sprem(116);
    }
  }
  &.deco04 {
    right: 0;
    left: vw(326);
    @include mq(sp) {
      left: sprem(190);
      width: sprem(88);
    }
  }
  &.deco05 {
    right: 0;
    left: vw(418);
    @include mq(sp) {
      left: sprem(200);
      width: sprem(118);
      margin-top: 0;
    }
  }
  @include mq(sp) {
    margin: sprem(12) auto 0;
  }
}

/* oneday_intro
********************************************** */
.oneday_intro {
  position: relative;
  width: 100%;
  height: vw(1000);
  background-image: url(/wp/wp-content/themes/standard_sass/images/oneday/intro-bg.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: rem(-108);
  @include mq(sp) {
    background-image: url(/wp/wp-content/themes/standard_sass/images/oneday/intro-bg_sp.jpg);
    height: sprem(520);
    margin-top: sprem(-90);
  }
}
.oneday_intro--deco {
  position: relative;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  z-index: 1;
  animation: oneday_move2 18s linear infinite;
  img {
    margin-top: rem(-180);
    transform: scale(-1, 1);
    @include mq(sp) {
      object-fit: cover;
      margin-top: sprem(-155);
    }
  }
  @include mq(sp) {
    animation: oneday_move2-sp 8s linear infinite;
  }
}
.oneday_intro--txt {
  font-size: rem(24);
  line-height: 1.7;
  letter-spacing: 0.08em;
  color: var(--color-wht);
  padding-top: vw(294);
  @include mq(sp) {
    font-size: sprem(14);
    padding-top: sprem(168);
  }
}

/* oneday_private
********************************************** */
.oneday_private {
  padding-block: vw(40) vw(260);
  @include mq(sp) {
    padding-block: sprem(20) sprem(130);
    .ttl-01-sub {
      padding-bottom: vw(145);
    }
  }
}
.private--img {
  margin-top: vw(122);
  @include mq(sp) {
    width: sprem(266);
    margin-top: sprem(10);
    margin-left: auto;
    margin-right: sprem(29);
  }
}
.private--area {
  position: relative;
  @include mq(sp) {
    display: flex;
    flex-direction: column-reverse;
  }
}
.private--ttl {
  display: flex;
  align-items: center;
  font-size: rem(20);
  line-height: 2.2;
  color: var(--color-primary-default);
  padding-left: vw(30);
  &::before {
    content: "";
    display: block;
    width: vw(8);
    height: vw(8);
    border-radius: 100px;
    background-color: var(--color-primary-default);
    margin-right: vw(8);
    @include mq(sp) {
      width: sprem(4);
      height: sprem(4);
      margin-right: sprem(4);
    }
  }
  @include mq(sp) {
    font-size: sprem(13);
    padding-left: sprem(15);
  }
}
.private--txt {
  padding: vw(22) vw(32);
  border-radius: 100px;
  background-color: #f3c0b6;
  line-height: 1.6;
  text-align: center;
  @include mq(sp) {
    padding: sprem(11) sprem(16);
  }
}
.private--list {
  position: absolute;
  width: fit-content;
  height: fit-content;
  min-width: vw(334);
  &::before {
    content: "";
    position: absolute;
    bottom: vw(-22);
    right: 0;
    left: 0;
    margin: 0 auto;
    width: vw(27);
    height: vw(22);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/oneday/triangle.svg);
    mask-size: 100%;
    mask-repeat: no-repeat;
    background-color: #f3c0b6;
    @include mq(sp) {
      bottom: sprem(-10);
      width: sprem(14);
      height: sprem(11);
    }
  }
  &.list01 {
    top: rem(75);
    left: rem(146);
  }
  &.list02 {
    top: rem(63);
    right: rem(115);
    @include mq(sp) {
      margin-left: auto;
    }
  }
  &.list03 {
    top: rem(279);
    left: rem(40);
  }
  &.list04 {
    top: rem(267);
    right: rem(72);
    @include mq(sp) {
      margin-left: auto;
    }
  }
  &.list05 {
    bottom: rem(245);
    left: rem(200);
  }
  &.list06 {
    bottom: rem(258);
    right: rem(199);
    @include mq(sp) {
      margin-left: auto;
    }
  }
  &.list07 {
    bottom: rem(68);
    left: rem(111);
  }
  &.list08 {
    bottom: rem(55);
    right: rem(61);
    @include mq(sp) {
      margin-left: auto;
    }
  }
  @include mq(sp) {
    position: relative;
    display: block;
    top: auto !important;
    bottom: auto !important;
    left: auto !important;
    right: auto !important;
    min-width: sprem(140);
    margin-bottom: sprem(32);
  }
}
.private--list_dark {
  padding-top: vw(82);
  .private--txt {
    position: relative;
    width: fit-content;
    font-size: rem(24);
    line-height: 1.7;
    letter-spacing: 0.08em;
    padding: vw(40) vw(90);
    background-color: var(--color-primary-default);
    color: var(--color-wht);
    margin-inline: auto;
    &::before {
      content: "";
      position: absolute;
      top: vw(-21);
      right: 0;
      left: 0;
      margin: 0 auto;
      width: vw(27);
      height: vw(22);
      mask-image: url(/wp/wp-content/themes/standard_sass/images/oneday/triangle.svg);
      mask-size: 100%;
      mask-repeat: no-repeat;
      background-color: var(--color-primary-default);
      transform: rotate(180deg);
      @include mq(sp) {
        top: sprem(-10);
        width: sprem(14);
        height: sprem(11);
      }
    }
    @include mq(sp) {
      font-size: sprem(13);
      padding: sprem(20) sprem(45);
    }
  }
  @include mq(sp) {
    padding-top: sprem(41);
  }
}

/* oneday_youtube
********************************************** */
.oneday_youtube {
  padding-block: vw(160) vw(260);
  @include mq(sp) {
    padding-block: sprem(20) sprem(108) !important;
  }
}
.oneday_youtube .posts-youtube {
  margin-bottom: vw(32);
  li {
    width: 48%;
  }
  @include mq(sp) {
    margin-bottom: 0;
  }
}
@include mq(sp, min, ps) {
  .oneday_youtube .ttl-01::before {
    content: none;
  }
}
@include mq(sp) {
  .oneday_youtube .column--youtube {
    padding-top: sprem(41);
  }
}

/* --- safari ipad --- */
@media screen and (max-width: 1440px) and (min-width: 768px) {
  ::-webkit-full-page-media,
  :future,
  :root .profile02 {
    left: vw(125);
  }
  ::-webkit-full-page-media,
  :future,
  :root .profile03 {
    left: vw(224);
  }
  ::-webkit-full-page-media,
  :future,
  :root .profile05 {
    right: vw(71);
  }
  ::-webkit-full-page-media,
  :future,
  :root .profile06 {
    right: vw(314);
  }
  ::-webkit-full-page-media,
  :future,
  :root .schedule--inner {
    width: vw(1376);
  }
  ::-webkit-full-page-media,
  :future,
  :root .schedule--icon::after {
    height: vw(350);
  }
  ::-webkit-full-page-media,
  :future,
  :root .schedule--deco.deco01 {
    right: vw(461);
  }
  ::-webkit-full-page-media,
  :future,
  :root .schedule--deco.deco02 {
    right: vw(500);
  }
  ::-webkit-full-page-media,
  :future,
  :root .schedule--deco.deco03 {
    right: vw(522);
  }
  ::-webkit-full-page-media,
  :future,
  :root .schedule--deco.deco04 {
    left: vw(426);
  }
  ::-webkit-full-page-media,
  :future,
  :root .schedule--deco.deco05 {
    left: vw(518);
  }
  ::-webkit-full-page-media,
  :future,
  :root .oneday_private .inner {
    max-width: vw(1700);
  }
}
