@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.page_ttl--bg {
  position: absolute;
  inset: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.page_ttl-jp {
  font-size: rem(48);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: rem(16);
  letter-spacing: normal;
  @include mq(sp) {
    font-size: sprem(14);
  }
}

/* --- page_ttl（タイトル画像なし） --- */
.page_ttl:not(.page_ttl-has_bg) {
  padding-top: rem(32);
  @include mq(sp) {
    padding-top: sprem(24);
  }
  .breadcrumbs {
    text-align: center;
  }
}

/* --- page_ttl（タイトル画像あり） --- */
.page_ttl-has_bg {
  height: rem(480);
  @include mq(sp) {
    height: sprem(200);
  }
  & > .inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .breadcrumbs {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .breadcrumbs span span:not(:last-child)::after {
    background-color: var(--color-wht);
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04 {
  position: relative;
  letter-spacing: 0.1em;
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: 0.95em;
  }
}
.ttl-01 {
  font-size: rem(56);
  line-height: 1.6;
  color: var(--color-primary-default);
  span {
    letter-spacing: 0.12em;
  }
  &::before {
    content: "";
    display: block;
    width: rem(62);
    height: rem(62);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/ttl-01_deco.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    margin-inline: auto;
    margin-bottom: vw(32);
    @include mq(sp) {
      width: sprem(32);
      height: sprem(32);
      margin-bottom: sprem(18);
    }
  }
  @include mq(sp) {
    font-size: sprem(30);
  }
  &.font-en {
    font-family: var(--font-en2);
  }
  & + .ttl-01-sub {
    font-family: var(--font-en);
    font-size: rem(24);
    font-weight: 400;
    line-height: 1.7;
    color: var(--color-secondary-default);
    margin-top: rem(-30);
    span {
      letter-spacing: normal;
    }
    @include mq(sp) {
      font-size: sprem(13);
      margin-top: sprem(-20);
    }
  }
  &.clr-wh + .ttl-01-sub {
    color: var(--color-wht);
    &::after {
      background-color: var(--color-wht);
    }
  }
}
.ttl-01-sub {
  display: block;
  color: var(--color-primary-default);
  font-size: calc-fz(16);
  letter-spacing: 0.1em;
  @include mq(sp) {
    font-size: sprem(13);
  }
  &.txt-ctr::after {
    @include auto-margin;
  }
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: perc(48, 16, em);
  }
}
.ttl-02 {
  font-size: rem(48);
  @include mq(sp) {
    font-size: sprem(28);
  }
}
.ttl-03 {
  font-size: rem(40);
  line-height: 1.7;
  padding-bottom: vw(45);
  color: var(--color-primary-default);
  margin-bottom: vw(56) !important;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: vw(102);
    height: vw(8);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/ttl-03_deco.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    @include mq(sp) {
      width: sprem(85);
      height: sprem(7);
    }
  }
  @include mq(sp) {
    font-size: sprem(24);
    padding-bottom: sprem(22);
    margin-bottom: sprem(28) !important;
  }
}
.ttl-03.txt-ctr::before {
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}
.ttl-04 {
  font-size: rem(32);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.home-ttl {
  font-size: rem(56);
  line-height: 1.6;
  letter-spacing: 0.12em;
  color: var(--color-primary-default);
  margin-top: vw(-53);
  margin-left: vw(20);
  @include mq(sp) {
    font-size: sprem(32);
    margin-block: sprem(-26) sprem(16);
    margin-left: sprem(10);
  }
}
.home-ttl-sub {
  font-size: rem(100);
  font-weight: 400;
  line-height: 0.86;
  letter-spacing: 0.01em;
  color: var(--color-primary-default);
  opacity: 0.1;
  @include mq(sp) {
    font-size: sprem(54);
  }
}
