@use "../abstracts" as *;

/* breadcrumbs
**************************************** */
.breadcrumbs .inner {
  padding: rem(16) 0;
  white-space: nowrap;
  @include mq(sp, min, ps) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @include mq(sp) {
    overflow: auto;
  }
}
.breadcrumbs a {
  &:hover {
    color: var(--color-primary-default);
  }
}
.breadcrumbs span span:not(:last-child)::after {
  content: "";
  display: inline-block;
  @include rect(5, 7);
  clip-path: polygon(0 100%, 0 0, 100% 50%);
  background-color: #ccc;
  margin: 0.15em 0.5em 0.15em 1em;
}
