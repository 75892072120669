@use "../abstracts" as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

.main_side {
  display: grid;
  grid-template-columns: minmax(0, 1fr) rem(264);
  gap: rem(80);
  @include mq(med) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: sprem(40);
  }
}

/* main_column
**************************************** */
.main_column {
  flex: 1;
  overflow: hidden;
}

/* --- post --- */
.post {
  position: relative;
}
.post--img {
  background-color: var(--color-wht);
}
.post--img .img-contain {
  max-width: 80%;
}
.post--link {
  &:not([href="javascript:void(0);"]):hover {
    color: var(--color-primary-default);
  }
  &:hover svg {
    fill: var(--color-primary-default);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.post--date {
  font-size: rem(18);
  line-height: 1.6;
  letter-spacing: normal;
  color: var(--color-text-secondary);
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: rem(2);
}
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: rem(14);
  line-height: 1.7;
  color: var(--color-secondary-dark);
  padding: vw(4) vw(16) vw(2);
  border: 1px solid;
  border-radius: 100px;
  margin-inline: vw(2);
  &:hover {
    color: var(--color-secondary-default);
    border: 1px solid var(--color-secondary-default);
  }
  @include mq(sp) {
    padding: sprem(2) sprem(10);
  }
}
.cat_list-address {
  padding-right: vw(6);
  margin-right: vw(30);
}
.cat_list-address a {
  position: relative;
  font-size: rem(18);
  line-height: 1.6;
  letter-spacing: 0.08em;
  color: var(--color-secondary-dark);
  &::before {
    content: "";
    display: inline-block;
    vertical-align: sub;
    width: rem(16);
    height: rem(21);
    background: url(/wp/wp-content/themes/standard_sass/images/common/icon-pin.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    margin-right: vw(8);
    @include mq(sp) {
      margin-right: sprem(6);
    }
  }
  &:hover {
    opacity: 0.7;
  }
}
.cat_list-tag a {
  position: relative;
  font-size: rem(14);
  line-height: 1.7;
  color: var(--color-text-secondary);
  padding-inline: vw(6);
  &::before {
    content: "";
    display: inline-block;
    vertical-align: sub;
    width: rem(18);
    height: rem(18);
    background: url(/wp/wp-content/themes/standard_sass/images/common/icon-tag.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    margin-right: vw(4);
    @include mq(sp) {
      margin-right: sprem(4);
    }
  }
  &:hover {
    opacity: 0.7;
  }
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.cat_list-tag {
  display: contents;
  text-align: left;
  @include mq(sp) {
    margin-inline: sprem(2);
  }
}

/* side_column
**************************************** */
.side_section:not(:last-child) {
  margin-bottom: rem(64);
  @include mq(sp) {
    margin-bottom: sprem(32);
  }
}
.side--ttl {
  margin-bottom: vw(20);
  padding: rem(16);
  color: #fff;
  font-size: rem(20);
  line-height: 1.4;
  border-radius: 4px;
  background-color: var(--color-primary-default);
  @include mq(sp) {
    margin-bottom: sprem(16);
  }
}
.side--ttl span {
  opacity: 0.5;
  letter-spacing: 0.15em;
  display: block;
  line-height: 1;
  margin-top: rem(8);
}

/* --- list --- */
.side--list a {
  display: block;
  font-size: rem(18);
  line-height: 1.4;
  padding: rem(14) rem(8);
  border-bottom: solid 1px var(--color-text-placeholder);
  &:hover {
    color: var(--color-primary-default);
  }
}

/* --- post --- */
.posts-side {
  .post {
    display: grid;
    grid-template-columns: rem(64) minmax(0, 1fr);
    gap: rem(16);
    align-items: center;
    &:not(:last-child) {
      margin-bottom: rem(16);
    }
  }
  .post--date {
    margin-bottom: 0;
  }
  .post--ttl {
    line-height: var(--line-height-hdr);
  }
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
  &::after {
    content: "";
    display: block;
    @include rect(8);
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(135deg);
    transition: var(--transit-default);
  }
  &.active::after {
    transform: rotate(315deg);
  }
}
.archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
  .archive_list {
    position: relative;
    &:not(:last-child) {
      margin-right: rem(24);
    }
  }
  .archive_list a {
    display: block;
    padding: 0.25em 1em;
    text-align: left;
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-bg-02);
    }
    &:hover {
      background-color: var(--color-bg-02);
    }
  }
  .archive_list--label {
    margin-right: rem(8);
  }
  .archive_list--btn {
    cursor: pointer;
    border: none;
    outline: none;
    appearance: none;
    padding: 0.25em 4em 0.25em 2em;
    background-color: var(--color-wht);
    border: 1px solid #ccc;
    color: inherit;
    font-family: var(--font-primary);
    font-size: calc-fz(16);
    line-height: var(--line-height-default);
    font-weight: 500;
    transition: var(--transit-default);
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0.4em;
      right: 0.8em;
      margin: auto;
      width: 0.6em;
      height: 0.6em;
      border-bottom: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
      transition: var(--transit-default);
    }
    &:hover {
      background-color: var(--color-bg-02);
    }
  }
  .active .archive_list--btn::after {
    bottom: -0.2em;
    transform: rotate(225deg);
  }
  .archive_list--menu {
    position: absolute;
    z-index: 1;
    width: 100%;
    background-color: var(--color-wht);
    border: 1px solid #ccc;
    visibility: hidden;
    margin-top: -1px;
    opacity: 0;
    transition: var(--transit-default);
  }
  .active .archive_list--menu {
    visibility: visible;
    opacity: 1;
  }
}

/* blog
**************************************** */
.archive-blog .main_column,
.archive-blog .side_section {
  padding-top: vw(16);
  @include mq(sp) {
    padding-block: sprem(16);
  }
}
@include mq(sp) {
  .archive-blog .main_column {
    margin-top: sprem(-24);
  }
}
.posts-blog {
  gap: 4%;
  .post--img {
    border-radius: rem(16) rem(16) 0 0;
    img {
      border-radius: rem(16) rem(16) 0 0;
    }
  }
  .post--txtarea {
    padding: vw(40);
    word-break: break-word;
    @include mq(sp) {
      padding: sprem(24);
    }
  }
  .post--ttl {
    font-size: rem(20);
    line-height: 1.7;
    padding-top: rem(12);
    .post--link {
      letter-spacing: 0.08em;
    }
    @include mq(sp) {
      font-size: sprem(20);
      padding-top: sprem(6);
    }
  }
  .blog--cat {
    margin-left: 1rem !important;
  }
}
.posts-blog .post {
  width: 45%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  border-radius: rem(16);
  margin-bottom: vw(40);
  transition: var(--transit-default);
  @include mq(sp, min, ps) {
    grid-template-columns: rem(280) minmax(0, 1fr);
    align-items: center;
  }
  @include mq(sp) {
    width: 89% !important;
    margin: sprem(12) auto;
  }
  &:hover {
    opacity: 0.7;
  }
}
@include mq(sp) {
  .archive-blog .container .inner {
    width: 95%;
  }
  .archive-blog .side_section {
    width: 90%;
    margin-inline: auto;
  }
  .blc .posts-blog {
    padding-bottom: sprem(16);
  }
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: rem(20);
  padding-bottom: rem(20);
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: var(--color-wht);
}

/* --- posts-news --- */
.posts-news {
  .post--info {
    @include mq(sp, min, ps) {
      margin-bottom: rem(12);
    }
    @include mq(sp) {
      margin-bottom: sprem(8);
    }
  }
  .post--ttl {
    font-size: rem(24);
    line-height: 1.7;
    @include mq(sp) {
      font-size: sprem(19);
    }
  }
  .post--link {
    letter-spacing: 0.08em;
  }
  .post--info.flex .post--date + .cat_list {
    margin-left: rem(16);
  }
}
.posts-news .post {
  position: relative;
  padding-right: vw(112);
  padding-block: vw(30);
  margin-bottom: 0;
  border-bottom: 1px solid var(--color-mono-03);
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transition: var(--transit-default);
  }
  &::before {
    right: 0;
    width: vw(56);
    height: vw(56);
    border-radius: 100px;
    background-color: var(--color-wht);
    @include mq(sp) {
      width: sprem(32);
      height: sprem(32);
    }
  }
  &::after {
    right: vw(18);
    width: vw(19);
    height: vw(18);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/btn-arrow.svg);
    mask-repeat: no-repeat;
    mask-size: 100%;
    background-color: var(--color-primary-light);
    @include mq(sp) {
      right: sprem(6);
      width: sprem(17);
      height: sprem(16);
      margin-top: -1px;
    }
  }
  &:hover {
    &::before {
      background-color: var(--color-primary-default);
    }
    &::after {
      background-color: var(--color-wht);
    }
  }
  &:first-child {
    border-top: 1px solid var(--color-mono-03);
  }
  @include mq(sp) {
    display: block;
    padding-right: sprem(56);
    padding-block: sprem(20);
  }
}

/* works
**************************************** */

/* --- works--archive --- */
.archive-works .side_column {
  margin-top: vw(40);
  @include mq(sp) {
    width: 90%;
    margin-inline: auto;
  }
}
@include mq(sp) {
  .archive-works .container .inner {
    width: 96% !important;
    margin-top: sprem(-32);
  }
}

/* --- posts-works --- */
.posts-works .post {
  position: relative;
  border-radius: 18px;
  border-top: 18px solid var(--color-primary-default);
  border-bottom: 18px solid var(--color-primary-default);
  background-color: #f9f7f6;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
  margin: vw(40) vw(24);
  padding: rem(62) rem(80);
  @include mq(sp) {
    border-radius: 16px;
    border-top: 16px solid var(--color-primary-default);
    border-bottom: 16px solid var(--color-primary-default);
    margin: sprem(40) sprem(24);
    padding: sprem(20) sprem(24) sprem(24);
  }
}
.posts-works {
  .post--ttl {
    font-size: rem(40);
    line-height: 1.7;
    margin-bottom: vw(2);
    .post--link {
      letter-spacing: 0.08em;
    }
    @include mq(sp) {
      font-size: sprem(26);
      margin-bottom: sprem(6);
    }
  }
  .post--info {
    margin-bottom: vw(24);
    @include mq(sp) {
      margin-bottom: sprem(16);
    }
  }
  .post--txtarea {
    flex: 1;
  }
  .post--heading {
    font-size: rem(24);
    line-height: 1.7;
    letter-spacing: 0.08em;
  }
  .post--img {
    margin-right: vw(60);
    border-radius: 16px;
    img {
      width: vw(520) !important;
      height: vw(400);
      border-radius: 16px;
      @include mq(sp) {
        width: 100% !important;
        height: sprem(200);
        border-radius: 12px;
      }
    }
    @include mq(sp) {
      margin-right: 0;
      border-radius: 12px;
    }
  }
  .btn a {
    margin-left: auto;
    margin-right: 0;
    margin-top: vw(30);
  }
  .post--date-latest {
    position: absolute;
    top: vw(22);
    right: vw(30);
    color: var(--color-text-secondary);
    font-size: rem(14);
    line-height: 1.7;
    @include mq(sp) {
      position: relative;
      top: auto;
      right: auto;
      font-size: sprem(12);
      padding-block: sprem(16) sprem(8);
    }
  }
  .new_date {
    font-size: rem(10);
    color: var(--color-secondary-dark);
    border: 1px solid;
    padding: vw(2) vw(10);
    border-radius: 100px;
    margin-right: vw(12);
    @include mq(sp) {
      padding: sprem(2) sprem(10) 0;
      margin-right: sprem(8);
    }
  }
}

/* --- post--voice_wrap --- */
.post--voice_wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: vw(20) vw(30);
  gap: vw(4);
  border-top: 1px solid;
  border-bottom: 1px solid;
  background: rgba(13, 42, 82, 0.05);
  @include mq(sp) {
    padding: sprem(16) sprem(20);
    gap: sprem(4);
    margin-block: sprem(16);
  }
}
