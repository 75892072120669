@use "../abstracts" as *;

/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: calc-clamp($fz-pc, 1080);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  @include mq(sp, min, ps) {
    @include mq(med) {
      font-size: rem($fz-sp);
      overflow-y: auto;
      overflow-x: auto;
    }
  }
  @include mq(sp) {
    font-size: min(spvw($fz-sp), rem($fz-sp));
  }
}
body {
  font-family: var(--font-primary);
  line-height: var(--line-height-default);
  font-weight: 500;
  font-feature-settings: "palt";
  color: var(--color-text-primary);
  height: 100%;
  padding-top: rem($header-hgt);
  word-break: break-word;
  @include mq(sp, min, ps) {
    // フォントサイズ変更する場合　例：18pxの例：calc-clamp(18, $wid-var)
    font-size: calc-clamp($fz-pc, $wid-var);
    @include mq(med) {
      &:not(.mce-content-body) {
        min-width: rem(1080);
      }
    }
  }
  @include mq(med) {
    padding-top: sprem($header-hgt-sp);
  }
  @include mq(sp) {
    -webkit-text-size-adjust: none;
  }
}
* {
  letter-spacing: var(--ltr-space-default);
}
ul,
ol {
  list-style: none;
}
small {
  font-size: calc-fz(14);
  @include mq(sp) {
    font-size: sprem(12);
  }
}
a,
a[href="javascript:void(0);"]:hover {
  color: var(--color-text-primary);
  text-decoration: none;
  transition: var(--transit-default);
  outline: none;
}
a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a:focus {
  text-decoration: none !important;
}
a[href^="tel:"] {
  @include mq(sp, min, ps) {
    pointer-events: none;
  }
}
img {
  vertical-align: middle;
  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}
address,
em,
i {
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: var(--line-height-hdr);
}
svg {
  transition: var(--transit-default);
  &[preserveAspectRatio="none"] {
    height: auto;
  }
}
.slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @include mq(med) {
    position: relative;
    bottom: rem(4);
  }
  @include mq(sp) {
    padding-top: sprem(8);
  }
}
.slick-dots li {
  display: block;
  @include rect(10);
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid var(--color-primary-default);
  cursor: pointer;
  transition: var(--transit-default);
  margin: rem(5);
  &.slick-active {
    background-color: var(--color-primary-default);
  }
  &:hover {
    opacity: 0.7;
  }
}

.bis-01,
.bis-02 {
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: vw(10);
    height: vw(10);
    background-color: var(--color-wht);
    border-radius: 100px;
    @include mq(sp) {
      width: sprem(5);
      height: sprem(5);
    }
  }
}
.bis-01 {
  &::before {
    top: vw(20);
    left: vw(20);
    @include mq(sp) {
      top: sprem(10);
      left: sprem(10);
    }
  }
  &::after {
    top: vw(20);
    right: vw(20);
    @include mq(sp) {
      top: sprem(10);
      right: sprem(10);
    }
  }
}
.bis-02 {
  &::before {
    bottom: vw(20);
    left: vw(20);
    @include mq(sp) {
      bottom: sprem(10);
      left: sprem(10);
    }
  }
  &::after {
    bottom: vw(20);
    right: vw(20);
    @include mq(sp) {
      bottom: sprem(10);
      right: sprem(10);
    }
  }
}
