@use "../abstracts" as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transit-default);
}
.icon-new_tab {
  @include rect(14);
}
.icon-pdf {
  @include rect(27, 12);
}
.lps_sec {
  position: relative;
  .bg-wh {
    background-color: var(--color-wht);
  }
  &:nth-child(odd)::before,
  &:nth-child(even)::before {
    content: "";
    position: absolute;
    top: vw(-150);
    width: vw(1920);
    height: vw(152);
    background-size: 100%;
    background-repeat: no-repeat;
    @include mq(sp) {
      top: sprem(-75);
      width: 100%;
      height: sprem(80);
      background-size: cover;
      background-position: center;
    }
  }
  &:nth-child(odd) {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn01.jpg);
    background-repeat: repeat;
    &:not(:first-child)::before {
      background-image: url(/wp/wp-content/themes/standard_sass/images/common/section-line01.png);
    }
    @include mq(sp) {
      background-size: contain;
    }
  }
  &:nth-child(even) {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn02.jpg);
    background-repeat: repeat;
    &::before {
      background-image: url(/wp/wp-content/themes/standard_sass/images/common/section-line02.png);
    }
    @include mq(sp) {
      background-size: contain;
    }
  }
}
.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
  &:not(:last-child) {
    margin-bottom: rem(80);
    @include mq(sp) {
      margin-bottom: sprem(40);
    }
  }
}
.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: rem(24);
}
.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  @include auto-margin;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: rem(40);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
@include mq(sp) {
  .lps_parts .btn {
    text-align: center;
  }
}
