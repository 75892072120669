@use "../abstracts" as *;
/* ##############################################################################

    KEYFRAMES

############################################################################## */
@keyframes oneday_move {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes oneday_move-sp {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(110%);
  }
}

@keyframes oneday_move2 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-10%);
  }
}
@keyframes oneday_move2-sp {
  0% {
    transform: translateX(110%);
  }
  100% {
    transform: translateX(-25%);
  }
}
