@use "../abstracts" as *;

/* ########################################################################

 スタンダード用TOP

######################################################################## */
/* hero
********************************************** */
.hero--cont {
  position: absolute;
  top: vw(236);
  left: vw(219);
  color: var(--color-text-light);
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
  @include mq(sp) {
    left: 8%;
    right: 8%;
    top: auto;
    bottom: 0;
    img {
      width: sprem(120) !important;
    }
  }
}
.hero--catch p {
  font-size: rem(56);
  line-height: 1.6;
  letter-spacing: 0.12em;
  padding-block: vw(18) vw(26);
  @include mq(sp) {
    font-size: sprem(32);
    line-height: 1.3;
    padding-block: sprem(18) sprem(20);
  }
}
.hero--bnr {
  position: absolute;
  display: inline-block;
  top: vw(500);
  right: vw(79);
  z-index: 9998;
  transition: 0.5s all;
  &:hover {
    transform: scale(1.1, 1.1);
    transition: 0.5s all;
    opacity: 0.8;
  }
  @include mq(sp) {
    width: 49%;
    bottom: sprem(-210);
    top: auto;
    right: sprem(4);
  }
}
.hero--deco {
  margin-top: vw(-128);
  position: relative;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  z-index: 1;
  img {
    width: 100% !important;
  }
  @include mq(sp) {
    display: none;
  }
}
.hero--scroll {
  position: absolute;
  bottom: vw(370);
  right: rem(-62);
  font-size: rem(14);
  line-height: 1.6;
  letter-spacing: normal;
  color: var(--color-primary-light);
  transform: rotate(90deg);
  &::before {
    content: "";
    width: vw(80);
    border-top: 0.5px solid;
    margin-right: vw(16);
  }
  @include mq(sp) {
    display: none;
  }
}

/* home_newsline
  ********************************************** */
.home_newsline {
  padding-block: rem(16);
}
.home_newsline .inner {
  gap: rem(40);
  @include mq(sp) {
    gap: sprem(10);
  }
}
.home_newsline .post {
  gap: rem(40);
  margin-block: rem(-16);
  padding-block: rem(16);
  @include mq(sp, min) {
    width: vw(870);
  }
}
.home_newsline--more {
  display: flex;
  gap: rem(14);
  margin-block: rem(-16);
  padding-block: rem(16);
  color: var(--color-primary-default);
  font-size: calc-fz(15);
  @include mq(sp) {
    margin-left: auto;
  }
}
.home_newsline--more svg {
  width: rem(16);
  aspect-ratio: 1;
  fill: currentColor;
}

/* 回り込みレイアウト
  ********************************************** */
.lps_parts--img_text:has(.inner-lg) {
  margin-inline: calc(50% - 50vw);
  counter-reset: number;
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
  .ttl-03 {
    font-size: rem(40);
    @include lh(40, 60);
    @include mq(sp) {
      font-size: sprem(28);
    }
  }
  .lps_parts + .lps_parts {
    margin-top: 0;
  }
  .img_text--txt {
    @include mq(sp, min, ps) {
      min-height: rem(720);
    }
    @include mq(sp) {
      padding-block: sprem(32);
    }
  }
}
.lps_parts--img_text .img {
  overflow: hidden;
}

/* スライド
  ********************************************** */
.slide-query .slick-list {
  margin-inline: calc(50% - 50vw);
}
.slide-query .slick-slide {
  transition: var(--transit-default);
  &:not(.slick-active) {
    opacity: 0.3;
  }
}
.slide-query .slick-arrow {
  position: absolute;
  z-index: 1;
  display: grid;
  place-items: center;
  @include rect(64);
  color: var(--color-primary-default);
  border-radius: 50%;
  background-color: var(--color-wht);
  &::before,
  &::after {
    content: "";
    position: absolute;
  }
  &::before {
    inset: 0;
    border-radius: 50%;
    box-shadow: 0 0 rem(32) var(--color-primary-default);
    opacity: 0.2;
  }
  &::after {
    @include rect(8);
    transform: rotate(45deg);
  }
}
.slide-query .slick-prev {
  &::after {
    border-bottom: 2px solid;
    border-left: 2px solid;
  }
}
.slide-query .slick-next {
  &::after {
    border-top: 2px solid;
    border-right: 2px solid;
  }
}

// posts-query01 ===============================================
.posts-query01 .post {
  width: rem(378);
  margin-inline: rem(16);
}
.posts-query01 .post--txt {
  text-align: justify;
}
.posts-query01 .slide-query {
  .slick-arrow {
    top: rem(93);
  }
  .slick-prev {
    left: rem(-48);
    @include mq(sp) {
      left: 0;
    }
  }
  .slick-next {
    right: rem(-48);
    @include mq(sp) {
      right: 0;
    }
  }
}

// posts-query02 ===============================================
.posts-query02 .post {
  width: rem(378);
  margin-inline: rem(16);
  padding: rem(38) rem(38) rem(54);
  background-color: var(--color-wht);
  box-shadow: 0 0 rem(32) rgba(0, 0, 0, 0.1);
  border-radius: rem(8);
}
.posts-query02 .post--num {
  display: block;
  color: var(--color-primary-default);
  font-size: rem(80);
  font-family: var(--font-en);
  text-align: center;
  line-height: var(--line-height-none);
  letter-spacing: 0;
}
.posts-query02 .post--img {
  width: rem(184);
  margin-inline: auto;
  overflow: hidden;
  border-radius: 50%;
  aspect-ratio: 1;
}
.posts-query02 .post--ttl {
  text-align: center;
}
.posts-query02 .post--txt {
  text-align: justify;
}
.posts-query02 .slide-query {
  .slick-track {
    display: flex;
  }
  .slick-list {
    padding-top: rem(103) !important;
    padding-bottom: rem(32) !important;
    @include mq(sp, min) {
      padding-left: calc(rem(378) - rem(16));
    }
  }
  .slick-slide {
    height: auto;
  }
  .slick-arrow {
    top: 0;
  }
  .slick-prev {
    right: rem(87);
  }
  .slick-next {
    right: 0;
  }
}

/* 背景写真カラム
  ********************************************** */
.bg_section {
  position: relative;
  display: flex;
  align-items: center;
  padding-block: rem(80);
  @include mq(sp, min, ps) {
    min-height: rem(720);
  }
  &:nth-of-type(odd) {
    .bg_section--txt {
      margin-left: auto;
    }
  }
}
.bg_section--img {
  position: absolute;
  inset: 0;
  z-index: -1;
}
@include mq(sp, min) {
  .bg_section--txt {
    width: rem(483);
  }
}

/* 新着情報、ブログ
  ********************************************** */

// home_information01 ===============================================
.home_information01 {
  display: flex;
  gap: rem(16);
  @include mq(sp) {
    flex-direction: column;
  }
  + .home_information01 {
    margin-top: rem(100);
  }
}
.home_information01--ttl {
  flex: 1;
}
@include mq(sp, min) {
  .home_information01--cont {
    width: rem(800);
  }
}
@include mq(sp) {
  .home_information01--ttl {
    text-align: center;
  }
  .home_information01--ttl .ttl-01-sub::after {
    margin-inline: auto;
  }
  .home_information01 .btn-arrow {
    text-align: center;
  }
}

.home_information01 {
  .posts-news .post--txt,
  .posts-blog .post--txt {
    -webkit-line-clamp: 1;
    opacity: 0.5;
    pointer-events: none;
  }
  .posts-news .post--txtarea {
    flex: 1;
  }
}

// home_information02 ===============================================
.home_information02--wrap {
  display: grid;
  gap: rem(20) rem(75);
  @include mq(sp, min, ps) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.home_information02 {
  @include mq(sp, min) {
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 2;
    gap: rem(10);
  }

  .posts-news .post--date,
  .posts-blog .post--date {
    font-size: calc-fz(13);
  }

  // posts-news
  .posts-news {
    padding: rem(40) rem(48);
    @include mq(sp) {
      padding: sprem(8) sprem(16);
    }
  }
  .posts-news .post {
    display: block;
    &:first-child {
      border-top: none;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .posts-news .post--txt {
    -webkit-line-clamp: 2;
    opacity: 0.5;
    font-size: calc-fz(14);
    @include lh(14, 22);
    pointer-events: none;
  }
  .posts-news .post--info {
    margin-bottom: rem(8);
  }
  .posts-news .post--ttl {
    margin-bottom: rem(8);
  }

  // posts-blog
  .posts-blog .post {
    padding: 0;
    border: none;
    gap: rem(32);
    @include mq(sp, min, ps) {
      grid-template-columns: rem(216) minmax(0, 1fr);
    }
    &:not(:last-child) {
      margin-bottom: rem(32);
    }
  }
  .posts-blog .post--info {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .posts-blog .cat_list {
    margin-left: 0 !important;
    margin-bottom: rem(8);
  }
}

/* home_about
  ********************************************** */
.home_about--bg {
  position: relative;
  margin-top: vw(-462);
  img {
    width: 100% !important;
  }
  @include mq(sp) {
    margin-top: sprem(-32);
  }
}
.about--catch {
  position: absolute;
  top: vw(763);
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  font-size: rem(24);
  line-height: 1.7;
  letter-spacing: 0.08em;
  color: var(--color-primary-default);
  @include mq(sp) {
    top: sprem(264);
    font-size: sprem(17);
    white-space: nowrap;
  }
}
.home_about--wrap {
  position: relative;
  margin-left: calc(50% - 50vw);
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  &::before {
    bottom: vw(-323);
    right: vw(409);
    width: vw(552);
    height: vw(616);
    background-image: url(/wp/wp-content/themes/standard_sass/images/about-brush01.svg);
    z-index: -1;
    @include mq(sp) {
      content: none;
    }
  }
  &::after {
    bottom: vw(-480);
    left: vw(70);
    width: vw(802);
    height: vw(424);
    background-image: url(/wp/wp-content/themes/standard_sass/images/about-img02.png);
    @include mq(sp) {
      bottom: sprem(-200);
      left: sprem(-8);
      width: sprem(240);
      height: sprem(175);
      background-image: url(/wp/wp-content/themes/standard_sass/images/about-img02_sp.png);
    }
  }
  @include mq(sp) {
    margin-left: auto;
  }
}
.about--txtarea {
  text-align: left;
  padding-top: vw(128);
  padding-left: vw(120);
  p {
    padding-bottom: vw(16);
    &:last-of-type {
      padding-bottom: vw(62);
    }
  }
  @include mq(sp) {
    padding-left: 0;
  }
}
.home_about {
  position: relative;
  margin-top: vw(-351);
  padding-bottom: vw(338);
  &::before {
    content: "";
    position: absolute;
    bottom: vw(111);
    right: 0;
    width: vw(417);
    height: vw(382);
    background-image: url(/wp/wp-content/themes/standard_sass/images/about-deco02.png);
    background-repeat: no-repeat;
    background-size: 100%;
    @include mq(sp) {
      bottom: sprem(37);
      right: 0;
      width: sprem(139);
      height: sprem(127);
    }
  }
  @include mq(sp) {
    margin-top: sprem(-64);
    padding-bottom: sprem(169);
  }
}
.about--ttl {
  position: relative;
  font-size: rem(40);
  letter-spacing: 0.08em;
  color: var(--color-primary-default);
  padding-bottom: vw(52);
  .num {
    font-size: rem(90);
    letter-spacing: normal;
    color: var(--color-secondary-dark);
    @include mq(sp) {
      font-size: sprem(50);
    }
  }
  .year {
    font-size: rem(40);
    color: var(--color-secondary-dark);
    @include mq(sp) {
      font-size: rem(30);
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: vw(-3);
    left: vw(-71);
    width: vw(210);
    height: vw(120);
    background-image: url(/wp/wp-content/themes/standard_sass/images/about-deco01.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    @include mq(sp) {
      top: sprem(-16);
      left: sprem(-8);
      width: sprem(140);
      height: sprem(80);
    }
  }
  @include mq(sp) {
    font-size: sprem(24);
  }
}
@include mq(sp) {
  .about--img {
    margin-right: auto;
    margin-left: calc(50% - 50vw);
  }
}

/* home_review
  ********************************************** */
.home_review {
  background-image: url(/wp/wp-content/themes/standard_sass/images/review-bg.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  padding-block: vw(296);
  @include mq(sp) {
    background-image: url(/wp/wp-content/themes/standard_sass/images/review-bg_sp.jpg);
    padding-block: sprem(80) sprem(152);
    background-size: cover;
  }
}
.home_review .btn a {
  box-shadow: 0px 0px 25px 0px var(--color-wht);
  margin-inline: auto;
  text-decoration: underline;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: rem(32);
    @include rect(8);
    border-top: 2px solid;
    border-right: 2px solid;
    rotate: 45deg;
    transition: var(--transit-default);
  }
}
.review--ttl {
  position: relative;
  font-size: rem(48);
  line-height: 1.6;
  letter-spacing: 0.12em;
  color: var(--color-text-light);
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.9);
  padding-bottom: vw(42);
  &::before {
    content: "";
    position: absolute;
    top: vw(15);
    right: vw(-108);
    width: vw(239);
    height: vw(112);
    background-image: url(/wp/wp-content/themes/standard_sass/images/review-deco01.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    @include mq(sp) {
      content: none;
    }
  }
  @include mq(sp) {
    font-size: sprem(26);
    padding-bottom: sprem(18);
  }
}
.google--box {
  position: relative;
  display: inline-flex;
  padding: vw(46) vw(100);
  border-radius: rem(24);
  background: rgba(253, 252, 248, 0.65);
  backdrop-filter: blur(15px);
  margin-bottom: vw(53);
  &::before {
    content: "";
    position: absolute;
    bottom: vw(-74);
    right: vw(-72);
    width: vw(227);
    height: vw(232);
    background-image: url(/wp/wp-content/themes/standard_sass/images/review-deco02.png);
    background-repeat: no-repeat;
    background-size: 100%;
    @include mq(sp) {
      bottom: sprem(-16);
      right: sprem(-26);
      width: sprem(113);
      height: sprem(116);
    }
  }
  @include mq(sp) {
    width: 100%;
    border-radius: sprem(12);
    padding: sprem(23) sprem(40);
    margin-bottom: sprem(26);
  }
}
.google--txtarea {
  line-height: 1.5;
  color: var(--color-primary-default);
  border-right: 2px solid var(--color-text-light);
  padding-right: vw(42);
  @include mq(sp) {
    border-right: none;
    padding-right: 0;
    padding-bottom: sprem(8);
  }
}
.google--ttl {
  font-size: rem(32);
  letter-spacing: 0.08em;
  @include mq(sp) {
    font-size: rem(26);
  }
}
.google--txt {
  letter-spacing: 0.08em;
}
.google--numarea {
  padding-left: vw(42);
  @include mq(sp) {
    padding-left: 0;
    justify-content: center;
    img {
      width: sprem(132) !important;
    }
  }
}
.google--num {
  font-size: rem(60);
  line-height: 1.1;
  letter-spacing: normal;
  color: var(--color-secondary-dark);
  padding-right: vw(24);
  white-space: nowrap;
  @include mq(sp) {
    font-size: sprem(42);
    padding-right: sprem(12);
  }
}

/* home_reason
  ********************************************** */
.home_reason {
  padding-block: vw(40) vw(260);
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: vw(765);
    height: vw(618);
    background-image: url(/wp/wp-content/themes/standard_sass/images/reason05-deco04.png);
    background-repeat: no-repeat;
    background-size: 100%;
    @include mq(sp) {
      bottom: sprem(72);
      left: sprem(-80);
      width: sprem(255);
      height: sprem(206);
    }
  }
  @include mq(sp) {
    padding-block: sprem(20) sprem(100);
  }
}
.home_reason .btn a {
  margin-inline: auto;
}
.reason--box {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: vw(120);
  &:nth-child(odd) .reason--txtarea {
    margin-right: vw(32);
    @include mq(sp) {
      margin-right: 0;
    }
  }
  &:nth-child(even) .reason--txtarea {
    margin-left: vw(32);
    @include mq(sp) {
      margin-left: 0;
    }
  }
  @include mq(sp) {
    display: block;
    padding-bottom: sprem(48);
  }
}
.reason--txtarea {
  width: vw(500);
  text-align: left;
  @include mq(sp) {
    width: 100%;
    padding-bottom: sprem(16);
  }
}
.reason--heading {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1.6;
  letter-spacing: normal;
  color: var(--color-primary-light);
  padding-bottom: vw(18);
  z-index: 1;
  &::before {
    content: "";
    width: vw(42);
    height: 1px;
    background-color: var(--color-primary-light);
    margin-right: vw(8);
  }
}
.reason--img {
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
.reason--box01 {
  &::before {
    content: "";
    position: absolute;
    top: vw(-236);
    left: 0;
    width: vw(497);
    height: vw(616);
    background-image: url(/wp/wp-content/themes/standard_sass/images/reason01-deco03.png);
    background-repeat: no-repeat;
    background-size: 100%;
    margin-left: calc(50% - 50vw);
    @include mq(sp) {
      top: sprem(-118);
      left: 0;
      width: sprem(248);
      height: sprem(308);
    }
  }
  .reason--img {
    margin-right: calc(50% - 50vw);
    &::before,
    &::after {
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      background-size: 100%;
    }
    &::before {
      top: vw(76);
      left: vw(-173);
      width: vw(191);
      height: vw(90);
      background-image: url(/wp/wp-content/themes/standard_sass/images/reason01-deco01.svg);
      @include mq(sp) {
        top: sprem(25);
        left: sprem(-16);
        width: sprem(63);
        height: sprem(30);
      }
    }
    &::after {
      bottom: vw(-40);
      left: vw(-69);
      width: vw(173);
      height: vw(250);
      background-image: url(/wp/wp-content/themes/standard_sass/images/reason01-deco02.png);
      @include mq(sp) {
        bottom: sprem(-20);
        left: sprem(-20);
        width: sprem(57);
        height: sprem(83);
      }
    }
  }
}
.reason--box02 {
  z-index: 1;
  .reason--img {
    &::before {
      top: vw(-80);
      left: vw(-100);
      width: vw(200);
      height: vw(200);
      background-image: url(/wp/wp-content/themes/standard_sass/images/reason02-deco01.svg);
      z-index: -1;
      @include mq(sp) {
        top: sprem(-26);
        left: auto;
        right: sprem(-20);
        width: sprem(66);
        height: sprem(66);
      }
    }
    &::after {
      bottom: vw(-58);
      right: vw(-76);
      width: vw(319);
      height: vw(155);
      background-image: url(/wp/wp-content/themes/standard_sass/images/reason02-deco02.png);
      @include mq(sp) {
        bottom: sprem(-19);
        right: sprem(-16);
        width: sprem(106);
        height: sprem(51);
      }
    }
  }
  @include mq(sp) {
    display: flex;
    flex-direction: column-reverse;
  }
}
.reason--box03 {
  &::before {
    content: "";
    position: absolute;
    bottom: vw(-80);
    right: 0;
    width: vw(252);
    height: vw(300);
    background-image: url(/wp/wp-content/themes/standard_sass/images/reason03-deco02.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    margin-right: calc(50% - 50vw);
  }
  .reason--img {
    z-index: 1;
    &::before {
      bottom: vw(-75);
      left: vw(-62);
      width: vw(224);
      height: vw(165);
      background-image: url(/wp/wp-content/themes/standard_sass/images/reason03-deco01.png);
      @include mq(sp) {
        bottom: sprem(-25);
        left: sprem(-20);
        width: sprem(74);
        height: sprem(55);
      }
    }
    &::after {
      bottom: vw(12);
      left: vw(-125);
      width: vw(165);
      height: vw(164);
      background-image: url(/wp/wp-content/themes/standard_sass/images/reason03-deco03.svg);
      z-index: -1;
      @include mq(sp) {
        bottom: sprem(4);
        left: sprem(-22);
        width: sprem(55);
        height: sprem(54);
      }
    }
  }
}
.reason--box04 {
  &::before {
    content: "";
    position: absolute;
    top: vw(-265);
    left: 0;
    width: vw(417);
    height: vw(616);
    background-image: url(/wp/wp-content/themes/standard_sass/images/reason04-deco01.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    margin-left: calc(50% - 50vw);
  }
  .reason--img {
    &::before {
      top: vw(46);
      left: vw(-123);
      width: vw(191);
      height: vw(90);
      background-image: url(/wp/wp-content/themes/standard_sass/images/reason01-deco01.svg);
      @include mq(sp) {
        top: sprem(15);
        left: sprem(-18);
        width: sprem(63);
        height: sprem(30);
      }
    }
    &::after {
      bottom: vw(-85);
      right: vw(-107);
      width: vw(275);
      height: vw(226);
      background-image: url(/wp/wp-content/themes/standard_sass/images/reason04-deco02.png);
      @include mq(sp) {
        bottom: sprem(-28);
        right: sprem(-21);
        width: sprem(91);
        height: sprem(75);
      }
    }
  }
  @include mq(sp) {
    display: flex;
    flex-direction: column-reverse;
  }
}
.reason--box05 {
  z-index: 1;
  padding-bottom: vw(100);
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-right: calc(50% - 50vw);
  }
  &::before {
    top: vw(-276);
    right: 0;
    width: vw(494);
    height: vw(635);
    background-image: url(/wp/wp-content/themes/standard_sass/images/reason05-deco01.svg);
    @include mq(sp) {
      top: sprem(-92);
      right: 0;
      width: sprem(164);
      height: sprem(211);
    }
  }
  &::after {
    bottom: vw(-307);
    right: vw(0);
    width: vw(497);
    height: vw(663);
    background-image: url(/wp/wp-content/themes/standard_sass/images/reason05-deco03.svg);
    z-index: -1;
    @include mq(sp) {
      bottom: sprem(-102);
      right: sprem(0);
      width: sprem(165);
      height: sprem(221);
    }
  }
  .reason--img {
    &::before {
      top: vw(37);
      right: vw(-60);
      width: vw(164);
      height: vw(55);
      background-image: url(/wp/wp-content/themes/standard_sass/images/reason05-deco02.svg);
      @include mq(sp) {
        top: sprem(12);
        right: sprem(-20);
        width: sprem(54);
        height: sprem(18);
      }
    }
  }
  @include mq(sp) {
    padding-bottom: sprem(80);
  }
}

/* home_menu
  ********************************************** */
.home_menu {
  position: relative;
  padding-block: vw(40) vw(148);
  &::after {
    content: "";
    position: absolute;
    top: vw(86);
    right: 0;
    width: vw(353);
    height: vw(352);
    background-image: url(/wp/wp-content/themes/standard_sass/images/menu-brush01.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    @include mq(sp) {
      top: sprem(28);
      right: 0;
      width: sprem(117);
      height: sprem(117);
    }
  }
  .ttl-01-sub {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: vw(-28);
      right: vw(-28);
      width: vw(302);
      height: vw(268);
      background-image: url(/wp/wp-content/themes/standard_sass/images/menu-deco01.png);
      background-repeat: no-repeat;
      background-size: 100%;
      z-index: 2;
      @include mq(sp) {
        top: sprem(-9);
        right: sprem(-9);
        width: sprem(167);
        height: sprem(148);
      }
    }
  }
  @include mq(sp) {
    padding-block: sprem(20) sprem(56);
  }
}
.home_menu .inner {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: vw(702);
    left: 0;
    width: vw(406);
    height: vw(616);
    background-image: url(/wp/wp-content/themes/standard_sass/images/menu-brush02.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    margin-left: calc(50% - 50vw);
    @include mq(sp) {
      top: sprem(234);
      width: sprem(135);
      height: sprem(205);
    }
  }
}
.home_menu .btn-arrow {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0 0 rem(16) rem(16);
  a {
    position: inherit;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    font-size: rem(23);
    line-height: 1.9;
    letter-spacing: 0.08em;
    color: var(--color-primary-default);
    padding: vw(20) vw(30);
    &::before {
      position: inherit;
      transform: none;
      width: vw(38);
      height: vw(38);
      margin-left: vw(8);
      @include mq(sp) {
        width: sprem(26);
        height: sprem(26);
        margin-left: sprem(4);
      }
    }
    &::after {
      top: auto;
      bottom: rem(26);
      right: vw(39);
      width: vw(18);
      @include mq(lg) {
        bottom: rem(24);
      }
      @include mq(sp) {
        bottom: sprem(12.5);
        right: sprem(20);
        width: sprem(14);
      }
    }
    @include mq(lg) {
      font-size: rem(24);
      line-height: 1.7;
    }
    @include mq(sp) {
      font-size: sprem(22);
      padding: sprem(10) sprem(15);
    }
  }
}
.bg-ptn05 {
  position: relative;
  padding: vw(20);
  border-radius: rem(18);
  z-index: 1;
  &:first-of-type {
    margin-bottom: vw(80);
  }
  &:last-child .menu--list {
    margin-inline: vw(40);
    @include mq(sp) {
      margin-inline: 0;
    }
  }
  &:last-child::before {
    content: "";
    position: absolute;
    top: vw(287);
    right: vw(277);
    width: vw(164);
    height: vw(55);
    background-image: url(/wp/wp-content/themes/standard_sass/images/menu-deco02.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 1;
    @include mq(sp) {
      top: auto;
      bottom: sprem(230);
      right: sprem(24);
      width: sprem(54);
      height: sprem(18);
    }
  }
  @include mq(sp) {
    padding: sprem(20);
    border-radius: sprem(9);
  }
}
.home_menu {
  .home-ttl-sub {
    font-size: rem(152);
    color: var(--color-text-light);
    opacity: 0.4;
    padding-top: vw(60);
    @include mq(sp) {
      font-size: sprem(52);
      padding-top: sprem(30);
    }
  }
  .home-ttl {
    position: relative;
    font-size: rem(48);
    margin-left: 0;
    margin-top: vw(-96);
    z-index: 1;
    @include mq(sp) {
      font-size: sprem(28);
      margin-top: sprem(-32);
    }
  }
}
.home_menu--wrap {
  padding: vw(40) vw(60) vw(60);
  @include mq(sp) {
    padding: sprem(16) 0 0;
  }
}
.menu--list {
  position: relative;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  border-radius: rem(500) rem(500) rem(32) rem(32);
  transition: var(--transit-default);
  img {
    border-radius: rem(500) rem(500) 0 0;
  }
  span {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &:hover {
    opacity: 0.8;
  }
  @include mq(sp) {
    margin-block: sprem(16);
  }
}

/* home_works
  ********************************************** */
.home_works--bg {
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  &::before {
    top: vw(-469);
    right: 0;
    width: vw(620);
    height: vw(581);
    background-image: url(/wp/wp-content/themes/standard_sass/images/menu-brush03.svg) !important;
    @include mq(sp) {
      top: sprem(-156);
      width: sprem(206);
      height: sprem(193);
    }
  }
  &::after {
    top: vw(-360);
    left: vw(92);
    width: vw(350);
    height: vw(472);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/cta-deco02.png);
    z-index: 1;
    @include mq(sp) {
      content: none;
    }
  }
}
.home_works--bg img {
  width: 100% !important;
}
.home_works {
  padding-block: vw(40) vw(240);
  .posts-works .post {
    margin-block: vw(34) vw(19);
    @include mq(sp) {
      min-height: sprem(672);
    }
  }
  .btn a {
    margin-top: vw(35);
    margin-inline: auto;
    @include mq(sp) {
      margin-top: sprem(18);
    }
  }
  @include mq(sp) {
    padding-block: sprem(20) sprem(120);
  }
}
.home_works.lps_sec {
  &::before {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/section-line_works.png) !important;
  }
}
.home_works--slide {
  position: relative;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  z-index: 1;
  .post {
    display: flex !important;
    width: vw(1140);
    margin-inline: vw(40) !important;
    @include mq(sp) {
      width: sprem(350);
    }
  }
  .post--ttl {
    text-align: left;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  &::before {
    top: vw(-320);
    right: vw(77);
    width: vw(668);
    height: vw(623);
    background-image: url(/wp/wp-content/themes/standard_sass/images/works-brush.svg);
  }
  &::after {
    bottom: vw(-280);
    left: vw(60);
    width: vw(552);
    height: vw(616);
    background-image: url(/wp/wp-content/themes/standard_sass/images/brush.svg);
    transform: rotate(100deg);
    z-index: -1;
    @include mq(sp) {
      bottom: sprem(-120);
      left: 0;
      width: sprem(184);
      height: sprem(205);
    }
  }
}
.home_works--slide {
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(80);
    height: rem(80);
    background-color: rgba(13, 42, 82, 0.9);
    border-radius: 100px;
    text-decoration: none;
    pointer-events: auto;
    cursor: pointer;
    transition: var(--transit-default);
    @include mq(sp) {
      top: 22%;
      width: sprem(40);
      height: sprem(40);
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .slick-prev::before,
  .slick-next::before {
    content: "";
    display: block;
    @include rect(24);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/slide-arrow.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    @include mq(sp) {
      @include rect(16);
    }
  }
  .slick-prev {
    left: vw(340);
    @include mq(sp) {
      left: vw(138);
    }
    &::before {
      transform: rotate(180deg);
    }
  }
  .slick-next {
    right: vw(340);
    @include mq(sp) {
      right: vw(138);
    }
  }
}

/* home_staff
  ********************************************** */
.home_staff {
  padding-top: vw(40);
  .ttl-01 {
    margin-bottom: vw(82) !important;
    @include mq(sp) {
      margin-bottom: sprem(201) !important;
    }
  }
  @include mq(sp) {
    padding-top: sprem(32);
  }
}
.home_staff--wrap {
  position: relative;
  padding-bottom: vw(285);
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: vw(380);
    height: vw(1112);
    background-image: url(/wp/wp-content/themes/standard_sass/images/staff-bg.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    @include mq(sp) {
      content: none;
    }
  }
  &::before {
    right: 0;
  }
  &::after {
    left: 0;
  }
  @include mq(sp) {
    padding-bottom: sprem(213);
    .inner {
      position: relative;
      z-index: 2;
    }
  }
}
.staff--img-lft,
.staff--img-rgt {
  position: relative;
  z-index: 1;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  @include mq(sp) {
    position: initial;
  }
}
.staff--img-lft {
  &::before {
    top: vw(100);
    left: 0;
    width: vw(520);
    height: vw(611);
    background-image: url(/wp/wp-content/themes/standard_sass/images/staff-img01.png);
    @include mq(sp) {
      top: sprem(203);
      left: sprem(50);
      width: sprem(170);
      height: sprem(150);
      background-image: url(/wp/wp-content/themes/standard_sass/images/staff-img01_sp.png);
    }
  }
  &::after {
    top: vw(566);
    left: vw(146);
    width: vw(386);
    height: vw(348);
    background-image: url(/wp/wp-content/themes/standard_sass/images/staff-img02.png);
    @include mq(sp) {
      top: auto;
      bottom: sprem(50);
      left: sprem(53);
      width: sprem(155);
      height: sprem(140);
      background-image: url(/wp/wp-content/themes/standard_sass/images/staff-img02_sp.png);
    }
  }
}
.staff--img-rgt {
  &::before {
    top: vw(141);
    right: vw(164);
    width: vw(301);
    height: vw(370);
    background-image: url(/wp/wp-content/themes/standard_sass/images/staff-img03.png);
    @include mq(sp) {
      top: sprem(204);
      right: sprem(41);
      width: sprem(121);
      height: sprem(149);
      background-image: url(/wp/wp-content/themes/standard_sass/images/staff-img03_sp.png);
    }
  }
  &::after {
    top: vw(546);
    right: 0;
    width: vw(559);
    height: vw(480);
    background-image: url(/wp/wp-content/themes/standard_sass/images/staff-img04.png);
    @include mq(sp) {
      top: auto;
      bottom: sprem(50);
      right: 0;
      width: sprem(213);
      height: sprem(148);
      background-image: url(/wp/wp-content/themes/standard_sass/images/staff-img04_sp.png);
    }
  }
}
.home_staff .btn a {
  margin-inline: auto;
}
.staff--txt {
  font-size: rem(18);
  line-height: 2.2;
  &:last-of-type {
    padding-bottom: vw(60);
    @include mq(sp) {
      padding-bottom: sprem(16);
    }
  }
  @include mq(sp) {
    font-size: sprem(14);
    line-height: 2;
  }
}
.staff--heading {
  font-size: rem(30);
  line-height: 1.7;
  letter-spacing: 0.08em;
  color: var(--color-secondary-dark);
  padding-block: vw(32);
  @include mq(sp) {
    font-size: sprem(20);
    padding-block: sprem(12);
  }
}

/* home_oneday
  ********************************************** */
.home_oneday {
  position: relative;
  width: 100%;
  height: vw(960);
  background-image: url(/wp/wp-content/themes/standard_sass/images/onday-img.png);
  background-repeat: no-repeat;
  background-size: 100%;
  margin-block: vw(-100) vw(-10);
  z-index: 1;
  .inner {
    max-width: vw(1300);
    @include mq(sp) {
      max-width: rem(1300);
    }
  }
  @include mq(sp) {
    background-image: url(/wp/wp-content/themes/standard_sass/images/oneday-img_sp.png);
    height: 100%;
  }
}
.oneday--wrap {
  flex-direction: column;
  width: vw(660);
  color: var(--color-text-light);
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  margin-left: auto;
  @include mq(sp) {
    width: 100%;
  }
}
.oneday--wrap .btn {
  margin-left: auto;
  margin-right: 0 !important;
  a {
    margin-left: auto;
    box-shadow: 0px 0px 25px 0px rgba(255, 255, 255, 0.4);
  }
  @include mq(sp) {
    width: 100%;
    margin-bottom: sprem(152);
  }
}
.oneday--ttl {
  position: relative;
  font-size: rem(56);
  line-height: 1.6;
  letter-spacing: 0.12em;
  padding-block: vw(66) vw(40);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: vw(-90);
    width: vw(134);
    height: vw(134);
    background-image: url(/wp/wp-content/themes/standard_sass/images/oneday-deco.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: -1;
    @include mq(1200) {
      content: none;
    }
  }
  @include mq(1984) {
    font-size: rem(54);
  }
  @include mq(sp) {
    font-size: sprem(28);
    padding-left: sprem(128);
    padding-block: sprem(76) sprem(68);
    margin-top: 0;
  }
}
.oneday--txt {
  &:first-of-type {
    padding-bottom: 1rem;
  }
  &:last-of-type {
    padding-bottom: vw(62);
    @include mq(sp) {
      padding-bottom: sprem(31);
    }
  }
  @include mq(sp) {
    color: var(--color-text-primary);
  }
}

/* home_column
  ********************************************** */
.home_column.bg-ptn02::before {
  content: none !important;
}
.column--wrap {
  position: relative;
  padding-block: vw(80);
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    width: vw(1600);
    height: vw(4);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/dot-line.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    @include mq(sp) {
      width: 100%;
      background-size: cover;
      height: sprem(2);
    }
  }
  &:last-child::before {
    content: none;
  }
  &:first-child {
    padding-top: vw(176);
    @include mq(sp) {
      padding-top: sprem(32);
    }
  }
  @include mq(sp) {
    width: 100%;
    padding-block: sprem(40);
  }
}
.column--wrap .btn-arrow {
  padding-top: vw(48);
  @include mq(sp) {
    padding-top: sprem(24);
  }
}
.column--cont {
  width: vw(920);
}

// column--blog
.column--blog .posts-blog .post {
  width: 47.5%;
}
.column--blog .posts-blog {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: vw(-343);
    top: vw(181);
    width: vw(552);
    height: vw(616);
    background-image: url(/wp/wp-content/themes/standard_sass/images/brush.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    @include mq(sp) {
      left: sprem(-114);
      top: sprem(220);
      width: sprem(184);
      height: sprem(205);
    }
  }
}

//home_youtube
.column--youtube .btn-arrow a::after {
  top: 0;
  bottom: 0;
  margin-block: auto;
  right: vw(22);
  width: vw(12);
  height: vw(12);
  transform: rotate(-45deg);
  mask-image: url(/wp/wp-content/themes/standard_sass/images/common/slide-arrow.svg);
  @include mq(sp) {
    top: 0;
    right: sprem(8.5);
    width: sprem(10);
    height: sprem(10);
  }
}
.posts-youtube li {
  width: 30%;
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
.youtube_box {
  aspect-ratio: 16/9;
  img {
    margin-bottom: vw(10);
    @include mq(sp) {
      margin-bottom: sprem(10);
    }
  }
  a:hover {
    opacity: 0.7;
  }
}

/* intro--wrap
  ********************************************** */
.intro--wrap img {
  width: 100% !important;
}

/* --- safari ipad --- */
@media screen and (max-width: 1440px) and (min-width: 768px) {
  ::-webkit-full-page-media,
  :future,
  :root .home_review {
    padding-block: vw(260);
  }
  ::-webkit-full-page-media,
  :future,
  :root .reason--box:nth-child(odd) .reason--txtarea {
    margin-right: vw(180);
  }
  ::-webkit-full-page-media,
  :future,
  :root .reason--box:nth-child(even) .reason--txtarea {
    margin-left: vw(180);
  }
  ::-webkit-full-page-media,
  :future,
  :root .reason--txtarea {
    width: vw(1000);
  }
  ::-webkit-full-page-media,
  :future,
  :root .menu--list {
    margin-inline: rem(8);
  }
  ::-webkit-full-page-media,
  :future,
  :root .home_menu .btn-arrow a::after {
    bottom: vw(23);
  }
  ::-webkit-full-page-media,
  :future,
  :root .home_works--bg::after {
    left: vw(64);
  }
}
