@use "../abstracts" as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: rem($header-hgt);
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn01.jpg);
  z-index: z(header);
  transition: var(--transit-default);
  border-top: rem(8) solid var(--color-primary-default);
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: vw(64);
    padding-right: vw(80);
    @include mq(sp) {
      padding-left: sprem(16) !important;
    }
  }
  @include mq(med) {
    height: sprem($header-hgt-sp);
    & > .inner {
      padding-left: rem(30);
    }
  }
  @include mq(sp) {
    border-top: sprem(4) solid var(--color-primary-default);
  }
}
.page-contact-simple .header {
  position: absolute;
}
.header-sm {
  @include mq(med, min, ps) {
    height: rem($header-hgt-fx);
  }
}

/* --- logo -- */
.header--logo a,
.footer--logo a {
  display: inline-block;
  &:hover {
    opacity: 0.7;
  }
}
.header--logo img {
  width: vw(240) !important;
  height: auto;
  @include mq(med) {
    width: auto;
    max-height: rem(40);
  }
  @include mq(sp) {
    width: sprem(180) !important;
    max-height: fit-content;
  }
}
.header--logo span {
  display: block;
  font-size: rem(12);
  padding-bottom: vw(4);
  text-align: center;
  @include mq(sp) {
    font-size: sprem(9);
  }
}

/* gnav
********************************************** */
.gnav,
.gnav ul,
.gnav li,
.gnav a {
  @include mq(med, min, ps) {
    height: 100%;
  }
}
.gnav {
  @include mq(med, min, ps) {
    display: flex;
  }
  @include mq(med) {
    position: fixed;
    top: sprem($header-hgt-sp);
    right: 0;
    z-index: 300;
    width: 100%;
    height: calc(100% - sprem($header-hgt-sp));
    margin: 0;
    padding: rem(24) 7%;
    opacity: 0;
    pointer-events: none;
    overflow: auto;
    background-color: var(--color-bg-02);
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default);
    &.active {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(0);
    }
  }
}
.gnav a {
  color: var(--color-primary-default);
  @include mq(med, min, ps) {
    @include center-flex;
  }
  @include mq(1201) {
    font-size: sprem(16);
  }
}

/* --- menu --- */
.gnav--menu,
.gnav--cta {
  @include mq(med) {
    display: block;
  }
}
.gnav--menu li {
  @include mq(med) {
    position: relative;
    display: block;
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px dotted var(--color-primary-default);
    }
  }
}
.gnav--link {
  padding: 0 rem(21);
  @include mq(med) {
    display: block;
    padding: rem(18) rem(16);
  }
  @include mq(med, min, ps) {
    text-align: center;
  }
}
.gnav--menu li:not(.menu-item-has-children) a::before {
  @include mq(med) {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: sprem(10);
    margin: auto;
    @include sprect(7);
    border-top: 1px solid var(--color-primary-default);
    border-right: 1px solid var(--color-primary-default);
    transform: rotate(45deg);
  }
}
.gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
.gnav--menu > li.current-menu-item > .gnav--link {
  @include mq(med, min, ps) {
    color: var(--color-secondary-default);
  }
}
.gnav--menu > li.menu-item-has-children > .gnav--link {
  padding: 0;
  @include mq(med) {
    display: block;
    padding: rem(18) rem(16);
  }
}
.gnav--menu > li.menu-item-has-children .gnav--link span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: rem(46);
  padding-left: rem(21);
  height: 100%;
  &::before {
    content: "";
    position: absolute;
    right: rem(25);
    width: rem(15);
    height: rem(10);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/header-arrow.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: 2px;
    @include mq(med) {
      content: none;
    }
  }
  @include mq(med) {
    padding: 0;
    justify-content: left;
  }
}

/* --- sub_nav --- */
.menu-item-has-children {
  position: relative;
}
.head_sub_nav--wrap {
  display: block;
  width: vw(220);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  z-index: 100;
  transition: var(--transit-default);
  opacity: 0;
  pointer-events: none;
  @include mq(med) {
    position: relative;
    width: 100%;
    border-top: 1px dotted var(--color-primary-default);
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
}
.head_sub_nav li .gnav--link span {
  padding: 0 !important;
  &::before {
    content: none !important;
  }
}
.gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
  @include mq(med, min, ps) {
    opacity: 1;
    pointer-events: auto;
  }
}
.menu-item-has-children.active .head_sub_nav--wrap {
  @include mq(med) {
    opacity: 1;
    pointer-events: auto;
  }
}
.head_sub_nav {
  @include mq(med) {
    position: relative;
  }
  li:last-child a {
    border-bottom: none;
  }
}
.head_sub_nav a {
  display: block;
  font-size: rem(15);
  padding: rem(10) rem(16);
  border-bottom: 1px solid var(--color-secondary-default);
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn01.jpg);
  &:hover {
    @include mq(med, min, ps) {
      color: var(--color-secondary-default);
    }
  }
  @include mq(sp) {
    padding: rem(18) rem(16);
    border-bottom: none;
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn02.jpg);
  }
}
.gnav_sub_toggle {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: absolute;
    top: 0;
    right: rem(-20);
    @include sprect(56);
    transition: var(--transit-default);
    color: var(--color-primary-default);
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: rem(14);
      height: 1px;
      border-bottom: 1px solid;
    }
    &::after {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(180deg);
    }
  }
}

/* ---btn --- */
.gnav_btn,
.tel_btn {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: fixed;
    top: 0;
    @include sprect(64, 74);
    cursor: pointer;
    z-index: 9999;
  }
}
.gnav_btn {
  @include mq(med) {
    right: 0;
    padding-right: sprem(10);
    transition: var(--transit-default);
    &.active {
      background-color: var(--color-bg-02);
      border-top: sprem(4) solid var(--color-primary-default);
    }
  }
}
.gnav_btn--lines {
  @include mq(med) {
    position: relative;
    @include sprect(24, 16);
  }
}
.gnav_btn--lines span {
  @include mq(med) {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--color-primary-default);
    transition: var(--transit-default);
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}
.active .gnav_btn--lines span {
  @include mq(med) {
    &:nth-of-type(1) {
      transform: translateY(sprem(7)) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(sprem(-7)) rotate(45deg);
    }
  }
}
.tel_btn {
  @include mq(med) {
    right: sprem(64);
    @include sprect(54, 74);
  }
  a {
    @include mq(med) {
      display: flex !important;
      width: 100%;
      height: 100%;
    }
  }
  svg {
    @include mq(med) {
      @include sprect(24);
      fill: var(--color-primary-default);
    }
  }
}
.gnav--cta {
  text-align: center;
}
.gnav--cta .cta_contact {
  a {
    font-size: rem(18);
    line-height: 1;
    color: var(--color-primary-default);
    border-right: 2px solid;
    border-bottom: 2px solid;
    border-left: 2px solid;
    padding: vw(24) vw(26) vw(35);
    @include mq(med, min, ps) {
      border-radius: 0 0 rem(16) rem(16);
      flex-direction: column;
    }
    @include mq(med) {
      justify-content: center;
      padding-block: sprem(16);
    }
    @include mq(sp) {
      margin-left: 0;
      border-top: 2px solid;
    }
  }
  img {
    margin-right: 0;
    padding-bottom: vw(17);
    @include mq(med) {
      width: sprem(30) !important;
      padding-bottom: 0;
      margin-right: sprem(8);
    }
  }
}
.gnav--cta li:not(:last-child) {
  @include mq(med) {
    margin-bottom: sprem(16);
  }
}
.gnav--tel {
  @include mq(med, min, ps) {
    margin-left: rem(8);
  }
}
.gnav--tel a {
  display: inline-flex !important;
  flex-direction: column;
}

/* --- safari ipad --- */
@media screen and (max-width: 1440px) and (min-width: 768px) {
  ::-webkit-full-page-media,
  :future,
  :root .gnav--link {
    font-size: rem(14);
  }
  ::-webkit-full-page-media,
  :future,
  :root .header > .inner {
    padding-left: vw(40);
    padding-right: vw(24);
  }
  ::-webkit-full-page-media,
  :future,
  :root .cta_contact {
    margin-left: rem(8);
  }
  ::-webkit-full-page-media,
  :future,
  :root .cta_tel .hours {
    font-size: rem(8);
  }
  ::-webkit-full-page-media,
  :future,
  :root .header--logo span {
    font-size: rem(8);
  }
}
